import { EnumSubscriptionModule } from '@apps/flying/shared';

import { EnumSidebarPage } from '../../sidebar-pages.interface';
import { IPagesListModuleAccess } from '../pages-list.interface';
import { EnumFlyingTutorialVideo, FLYING_VIDEOS } from '../../../../../shared/constants/youtube-tutorials/flying-youtube-videos.data';


/**------------------------------------------------------
 * Flying Research
 */
export const RESEARCH_PAGE_LIST_MODULE_DATA: IPagesListModuleAccess[] = [
	/* {
		id 		: EnumSidebarPage.ResearchBestSeller,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Best Sellers (Research Module)',
		message	: 'In here you can see the gold in print on demand. Watch and search the absolute bestsellers on Amazon. You have a variety of filters like a title search or a review sorting. Get insights and possibilities no one else can see. Six marketplaces(US, UK, DE, FR, IT, ES). Also, for Kindle Direct Publishing (KDP)!',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchBestSellers].videos
	}, */
	{
		id 		: EnumSidebarPage.ResearchSellerTrends,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Seller Trends (Research Module)',
		message	: 'Never miss out upcoming events or the newest trends. No matter if a week or three months, you can search for the periods you want. The most important thing, see the absolute newcomers first!',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchSellerTrends].videos
	},
	/* {
		id 		: EnumSidebarPage.ResearchSellerHistory,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Event Sellers (Research Module)',
		message	: `Did you ever wonder how the best sellers looked a few months ago, to check how they change over time? Well now you can, see the exact best sellers and their rankings at any point in time to check how the trends evolved over time. This may help you to forecast the next big trend in the print on demand market.`
		video 	: null
	}, */
	{
		id 		: EnumSidebarPage.ResearchDeleted,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Deleted (Research Module)',
		message	: 'Did you ever wonder what designs got a takedown or got removed recently? Well there can be many reasons for removing a design, and we may never know why, but now you can see all the designs which got removed. See all recent removals for every marketplace and all the different products.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchDeleted].videos
	},
	{
		id 		: EnumSidebarPage.ResearchRtr,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Real Time Research (Research Module)',
		message	: `An honorable person once said 'Live is Live' and LIVE is always good. Get real-time data directly from Amazon. Comprehensive statistics with keywords suggestions, Amazon, Google, and Google Trends suggestion. Copied by just a single click!`,
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchRealTimeResearch].videos
	},
	{
		id 		: EnumSidebarPage.ResearchNicheAnalysis,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Niche Analysis (Research Module)',
		message	: `Einstein invented the theory of relativity and Flying Research the MBA 'Niche Analysis'. Rumors say both calculations are similarly complex. Get a score from S+ till D for the keyword you search for. Of course with sellers, keyword suggestions, comparison and so on!`,
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchNicheAnalysisAndList].videos
	},
	/* {
		id 		: EnumSidebarPage.ResearchGetInspiredPod,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Get Inspired POD (Research Module)',
		message	: 'If Merch by Amazon is the queen, then you will find the prince princess here (you decide who is who). You will find newest and relevant designs from the platforms RedBubble and Spreadshirt for new ideas and variety.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchGetInspired].videos
	}, */
	{
		id 		: EnumSidebarPage.ResearchBrands,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Brands (Research Module)',
		message	: `It's like the Mona Lisa. Look but don't touch. But we are not in the Louvre, we are in the 'Brands' section where Marvel, Disney and Co. creeping around. And by the way you can search for any other brand related things here.`,
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchBrandsCategories].videos
	},
	/* {
		id 		: EnumSidebarPage.ResearchCategories,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Categories (Research Module)',
		message	: 'When you have no inspiration and no ideas you always can create some evergreens. Here you see topics and designs for exactly that.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchBrandsCategories].videos
	}, */
	/* {
		id 		: EnumSidebarPage.ResearchNicheList,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Niche Lists (Research Module)',
		message	: `Very similar to 'Categories'. More topics to choose from!`,
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchNicheAnalysisAndList].videos
	}, */
	{
		id 		: EnumSidebarPage.ResearchHobbyList,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Hobby List (Research Module)',
		message	: 'Everyone is looking for new niches and ideas. And here you find over 500 carefully selected hobbies and sports for your new designs. Did you know that "Story-Writing" for fan fiction is really popular, where people extend official stories from books, now you know! Create some designs for these interesting hobbies and see what happens.'
	},
	{
		id 		: EnumSidebarPage.ResearchFavorites,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Favorites (Research Module)',
		message	: `Who is the person who once saw a design, was thinking how amazing it is and 5 minutes later couldn't find it again. Yeah exactly, that's you. So never let it happen again! Here you can save all your favorites, monitor, send it via mail and most important organize it via tags, so you have always what you want to see.`,
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchFavorites].videos
	},

	// Events
	/* {
		id 		: EnumSidebarPage.EventsEventSellers,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Event Sellers (Research Module)',
		message	: `If we could just know what were the exact bestsellers on last Christmas or St. Patrick's Day, then we could prepare with all that knowledge for these holidays. Oh, wait now you can, the Event Sellers show you exactly the bestseller ranks on these exact days for the event specific designs. Do your research with this unique data and get ahead on all the events with your designs.`
	}, */
	{
		id 		: EnumSidebarPage.EventsSocialMedia,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Social Media Holidays (Research Module)',
		message	: 'Everyone looks at the official events during a year, but did you know that on social media people celebrate additional evens? Like the "Black History Month", that one is important in the United States. These social media holidays are popular, and you can sell many designs on these when you time it well.'
	},
	/* {
		id 		: EnumSidebarPage.EventsCalendar,
		access	: [EnumSubscriptionModule.Research],
		title	: 'Calendar (Research Module)',
		message	: `Like 'Upcoming', just like a ... calendar. Click on the event you want, and you can start directly the research with Keywords, Designs, Google, Amazon, RedBubble, and Spreadshirt search!`,
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.ResearchEventsUpcomingCalendar].videos
	}, */

	// Trademarks
	/* {
		id 		: EnumSidebarPage.TrademarkSearch,
		access	: [EnumSubscriptionModule.Trademark],
		title	: 'TM Search (Trademark Module)',
		message	: 'You want it fast and only for the nice classes that are really relevant in POD? Then you find it here and nowhere better. Search in the WIPO, EUIPO, US, Great Britain, German, French, Italian, and Spanish offices for trademarks in less than a second. Or maybe two. Filter for filed and registered trademarks. We also have ended and expired TMs if you are interested. Check the TM directly in the official office with one click.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.TrademarkSearch].videos

	}, */
	{
		id 		: EnumSidebarPage.TrademarkRegistration,
		access	: [EnumSubscriptionModule.Trademark],
		title	: 'TM Registrations (Trademark Module)',
		message	: `New stuff gets filed and registered all the time. Here you get the newest arrivals of every week. With all the filters like you have it in the 'TM Search'.`,
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.TrademarkRegistrations].videos

	},
	{
		id 		: EnumSidebarPage.TrademarkWatchlist,
		access	: [EnumSubscriptionModule.Trademark],
		title	: 'TM Watchlist (Trademark Module)',
		message	: 'Great in word games or funny phrases? Then congrats! But you should keep an eye that no one is registering this stuff. Track everything in our watchlist. Like the events, set up a reminder and get mail notifications if something would change. For you and your account safety.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.TrademarkWatchlist].videos
	},

	// Keywords
	{
		id 		: EnumSidebarPage.KeywordCompetition,
		access	: [EnumSubscriptionModule.KeywordPro],
		title	: 'KW Competition (Keyword Module)',
		message	: 'Find out how your competitors gained their rankings, and what keywords they are using, and get a score for the organic rank potential. With these insights, you can get ahead of your competition.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.KeywordSearch].videos
	},
	{
		id 		: EnumSidebarPage.KeywordHunter,
		access	: [EnumSubscriptionModule.KeywordPro],
		title	: 'KW Hunter (Keyword Pro Module)',
		message	: 'The most important thing for selling something online are keywords. Find the most valuable keywords for your listing and for your advertisement campaign. With Amazon and Google Search Volume, a 5 years interest trend and much more.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.KeywordHunter].videos
	},
	{
		id 		: EnumSidebarPage.ProductExplorer,
		access	: [EnumSubscriptionModule.KeywordPro],
		title	: 'Product Explorer (Keyword Pro Module)',
		message	: 'Get a detailed overview of the competing products. You are competing in the category clothing, where more than just Merch By Amazon products can rank. So you should have a look at the competitiveness and the relevance for clothing before you create designs.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.KeywordProductExplorer].videos
	},
	{
		id 		: EnumSidebarPage.KeywordSuggestions,
		access	: [EnumSubscriptionModule.KeywordBasic, EnumSubscriptionModule.KeywordPro],
		title	: 'KW Suggestions (Keyword Basic/Pro Module)',
		message	: 'Get insights about what users are searching on Amazon. These suggestions can boost your sales. With Google and Amazon Suggestions and a detailed keyword connection graph.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.KeywordSuggestions].videos
	},
	{
		id 		: EnumSidebarPage.KeywordLists,
		access	: [EnumSubscriptionModule.KeywordBasic, EnumSubscriptionModule.KeywordPro],
		title	: 'KW Lists (Keyword Basic/Pro Module)',
		message	: 'Save and organize your favorite keywords, and create reusable lists with categories. See evaluations of search volume for your keywords for more insights.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.KeywordLists].videos
	},

	// Keyword Advertisement
	{
		id 		: EnumSidebarPage.RankPositioning,
		access	: [EnumSubscriptionModule.KeywordPro],
		title	: 'Rank Positioning (Keyword Pro Module)',
		message	: 'Only for professional sellers. You can achieve high rankings with well-organized advertisement in highly competitive niches. The keywords you want to rank for need to generate sales on Amazon, so Amazon will pick up your rank for those keywords. Find the requirements for the individual keywords you want to rank for and position them with the right advertisement campaigns.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.KeywordRankPositioning].videos

	},
	{
		id 		: EnumSidebarPage.AdvertisingSuggestions,
		access	: [EnumSubscriptionModule.KeywordPro],
		title	: 'Ad Suggestions (Keyword Pro Module)',
		message	: 'The best keywords for advertising campaigns are product-specific. Find the most searched and effective keywords for your Merch by Amazon products that you plan to run Amazon campaigns for.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.KeywordAdSuggestions].videos
	},
	{
		id 		: EnumSidebarPage.AdvertisingLists,
		access	: [EnumSubscriptionModule.KeywordPro],
		title	: 'Ad Lists (Keyword Pro Module)',
		message	: 'The advertisement console of Amazon is not a good place to manage your advertisement lists. But we have built the perfect management tool to assist you with storing your ad lists, with keywords, negative keywords, product ASINs, and much more.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.KeywordAdLists].videos
	}
];
