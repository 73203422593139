import { EnumMbaColor } from './mba-amazon.constants';


/**------------------------------------------------------
 * Color Configurations (for Merch By Amazon)
 */
export const MBA_COLOR_SPECIFICATION: IAmazonMbaColor[] = [
	{
		code		: EnumMbaColor.NoColor,
		name		: 'No Color',
		colorCode	: EnumMbaColor.NoColor				// transparent color code
	},
	{
		code		: EnumMbaColor.Asphalt,
		name		: 'Asphalt',
		colorCode	: '#3f3e3c'
	},
	{
		code		: EnumMbaColor.BabyBlue,
		name		: 'Baby Blue',
		colorCode	: '#8fb8db'
	},
	{
		code		: EnumMbaColor.Black,
		name		: 'Black',
		colorCode	: '#000000'
	},
	{
		code		: EnumMbaColor.Brown,
		name		: 'Brown',
		colorCode	: '#31261d'
	},
	{
		code		: EnumMbaColor.Cranberry,
		name		: 'Cranberry',
		colorCode	: '#6e0a25'
	},
	{
		code		: EnumMbaColor.DarkHeather,
		name		: 'Dark Heather',
		colorCode	: '#494f4f',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.ForestGreen,
		name		: 'Forest Green',
		colorCode	: '#0f5b20'
	},
	{
		code		: EnumMbaColor.Grass,
		name		: 'Grass',
		colorCode	: '#5e9444'
	},
	{
		code		: EnumMbaColor.HeatherBlue,
		name		: 'Heather Blue',
		colorCode	: '#616691',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.HeatherGrey,
		name		: 'Heather Grey',
		colorCode	: '#d7dbdc',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.KellyGreen,
		name		: 'Kelly Green',
		colorCode	: '#006136'
	},
	{
		code		: EnumMbaColor.Lemon,
		name		: 'Lemon Yellow',
		colorCode	: '#f0e87b'
	},
	{
		code		: EnumMbaColor.Navy,
		name		: 'Navy',
		colorCode	: '#15232b'
	},
	{
		code		: EnumMbaColor.Olive,
		name		: 'Olive',
		colorCode	: '#4a4f26'
	},
	{
		code		: EnumMbaColor.Orange,
		name		: 'Orange',
		colorCode	: '#ff5c39'
	},
	{
		code		: EnumMbaColor.Pink,
		name		: 'Pink',
		colorCode	: '#f8a3bc'
	},
	{
		code		: EnumMbaColor.NeonPink,
		name		: 'Neon Pink',
		colorCode	: '#fe5bac'
	},
	{
		code		: EnumMbaColor.Purple,
		name		: 'Purple',
		colorCode	: '#514689'
	},
	{
		code		: EnumMbaColor.Red,
		name		: 'Red',
		colorCode	: '#b71111'
	},
	{
		code		: EnumMbaColor.Sapphire,
		name		: 'Sapphire Blue',
		colorCode	: '#0067a5'
	},
	{
		code		: EnumMbaColor.Royal,
		name		: 'Royal',
		colorCode	: '#1c4086'
	},
	{
		code		: EnumMbaColor.Silver,
		name		: 'Silver',
		colorCode	: '#cfd1d1'
	},
	{
		code		: EnumMbaColor.Slate,
		name		: 'Slate',
		colorCode	: '#818189'
	},
	{
		code		: EnumMbaColor.White,
		name		: 'White',
		colorCode	: '#ffffff'
	},
	{
		code		: EnumMbaColor.BlackAthleticHeather,
		name		: 'Black/Athletic Heather',
		colorCode	: '#aaaaac',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.BlackWhite,
		name		: 'Black/White',
		colorCode	: '#ffffff',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.DarkHeatherWhite,
		name		: 'Dark Heather/White',
		colorCode	: '#ffffff',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.NavyAthleticHeather,
		name		: 'Navy/Athletic Heather',
		colorCode	: '#aaaaac',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.NavyWhite,
		name		: 'Navy/White',
		colorCode	: '#ffffff',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.RedWhite,
		name		: 'Red/White',
		colorCode	: '#ffffff',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.RoyalBlueWhite,
		name		: 'Royal Blue/White',
		colorCode	: '#ffffff',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.DarkGreen,
		name		: 'Dark Green',
		colorCode	: '#2b4b39'
	},
	{
		code		: EnumMbaColor.Burgundy,
		name		: 'Burgundy',
		colorCode	: '#632131'
	},
	{
		code		: EnumMbaColor.GoldenYellow,
		name		: 'Golden Yellow',
		colorCode	: '#f8b50e'
	},
	{
		code		: EnumMbaColor.PurpleHeather,
		name		: 'Purple Heather',
		colorCode	: '#6e5c99',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.RedHeather,
		name		: 'Red Heather',
		colorCode	: '#cf3d69',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.OliveHeather,
		name		: 'Olive Heather',
		colorCode	: '#80837c',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.PinkHeather,
		name		: 'Pink Heather',
		colorCode	: '#ec6995',
		isColorIcon	: true
	},
	{
		code		: EnumMbaColor.SageGreen,
		name		: 'Sage Green',
		colorCode	: '#8b9d7e'
	},
	{
		code		: EnumMbaColor.BrightPink,
		name		: 'Bright Pink',
		colorCode	: '#e56594'
	},
	{
		code		: EnumMbaColor.DustyBlue,
		name		: 'Dusty Blue',
		colorCode	: '#8fa8c4'
	}
];


//** Interfaces --------------------------------- */
export interface IAmazonMbaColor {
	code		: EnumMbaColor;			// example: forest_green
	name		: string;				// example: Forest Green
	colorCode	: string;				// example: #0f5b20
	isColorIcon?: boolean;
}
