import { EnumLanguage } from '@libs/constants';


/**------------------------------------------------------
 * Marketplace Definitions
 */
export enum EnumAmazonMarketplace {
	All 		= 'all_markets',
	COM 		= 'com',
	CO_UK 		= 'co_uk',
	DE 			= 'de',
	FR 			= 'fr',
	IT 			= 'it',
	ES 			= 'es',
	JP 			= 'jp',

	// not used in mba
	NL 			= 'nl',			// Netherlands
	PL 			= 'pl',			// Poland
	SE 			= 'se',			// Sweden
	CA 			= 'ca',			// Canada
	AU 			= 'au',			// Australia
	MX 			= 'mx'			// Mexico
}


/**------------------------------------------------------
 * Product & Sorting Configurations
 */
export enum EnumAmazonProduct {
	All 			= 'all_products',
	Shirt 			= 'shirt',
	PremiumShirt 	= 'premium_shirt',
	VNeck 			= 'vneck',
	TankTop 		= 'tank_top',
	LongSleeve 		= 'long_sleeve',
	Raglan 			= 'raglan',
	Sweatshirt 		= 'sweatshirt',
	Hoodie 			= 'hoodie',
	PopSocket 		= 'popsockets',
	PhoneCase 		= 'phone_cases',
	ToteBag 		= 'tote_bag',
	ThrowPillow 	= 'throw_pillow',
	KDP 			= 'kdp'
}

export enum EnumAmazonSorting {
	Featured 	= 'featured',
	PriceAsc 	= 'price_asc',
	PriceDesc 	= 'price_desc',
	ReviewDesc 	= 'review_desc',
	Newest 		= 'newest'
}

export enum EnumAmazonCategory {
	All 		= 'all',
	Clothing 	= 'clothing',
	Book	 	= 'book'
}


/**------------------------------------------------------
 * Product Category Definitions
 */
export enum EnumAmazonFulfillmentType {
	MBA 		= 'mba',
	KDP 		= 'kdp',
	FBA 		= 'fba'
}


/**------------------------------------------------------
 * Fit Types
 */
export enum EnumAmazonFitType {
	Men 		= 'men',
	Women 		= 'women',
	Youth 		= 'youth',
	Unisex 		= 'unisex'
}


/**------------------------------------------------------
 * Colors
 */
export enum EnumAmazonColor {
	Asphalt 			= 'asphalt',
	BabyBlue 			= 'baby_blue',
	Black 				= 'black',
	Brown 				= 'brown',
	Cranberry 			= 'cranberry',
	DarkHeather 		= 'dark_heather',
	ForestGreen 		= 'forest_green',
	Grass 				= 'grass',
	HeatherBlue 		= 'heather_blue',
	HeatherGrey 		= 'heather_grey',
	KellyGreen 			= 'kelly_green',
	Lemon 				= 'lemon',
	Navy 				= 'navy',
	Olive 				= 'olive',
	Orange 				= 'orange',
	Pink 				= 'pink',
	NeonPink 			= 'neon_pink',
	Purple 				= 'purple',
	Red 				= 'red',
	Sapphire 			= 'sapphire',
	Royal 				= 'royal',
	Silver 				= 'silver',
	Slate 				= 'slate',
	White 				= 'white',
	BlackWhite 			= 'black_white',
	NavyWhite 			= 'navy_white',
	RedWhite 			= 'red_white',
	RoyalBlueWhite 		= 'royal_blue_white',
	BlackAthleticHeather = 'black_athletic_heather',
	DarkHeatherWhite 	= 'dark_heather_white',
	NavyAthleticHeather = 'navy_athletic_heather',
	PinkHeather  		= 'pink_heather',
	PurpleHeather  		= 'purple_heather',
	BrightBlueHeather  	= 'bright_blue_heather',
	OliveHeather  		= 'olive_heather',
	RedHeather  		= 'red_heather',
	SapphireBlue  		= 'sapphire_blue',
	Burgundy  			= 'burgundy',
	SageGreen  			= 'sage_green'
}


/**------------------------------------------------------
 * Mapping
 */
// eslint-disable-next-line @typescript-eslint/typedef
export const AMAZON_MARKETPLACE_LANGUAGES_CODES = {
	[EnumAmazonMarketplace.All]		: EnumLanguage.EN,
	[EnumAmazonMarketplace.COM]		: EnumLanguage.EN,
	[EnumAmazonMarketplace.CO_UK]	: EnumLanguage.EN,
	[EnumAmazonMarketplace.DE]		: EnumLanguage.DE,
	[EnumAmazonMarketplace.FR]		: EnumLanguage.FR,
	[EnumAmazonMarketplace.IT]		: EnumLanguage.IT,
	[EnumAmazonMarketplace.ES]		: EnumLanguage.ES,
	[EnumAmazonMarketplace.JP]		: EnumLanguage.JA,

	[EnumAmazonMarketplace.NL]		: EnumLanguage.NL,
	[EnumAmazonMarketplace.PL]		: EnumLanguage.PL,
	[EnumAmazonMarketplace.SE]		: EnumLanguage.SV,
	[EnumAmazonMarketplace.CA]		: EnumLanguage.EN,
	[EnumAmazonMarketplace.AU]		: EnumLanguage.EN,
	[EnumAmazonMarketplace.MX]		: EnumLanguage.ES
};

// eslint-disable-next-line @typescript-eslint/typedef
export const AMAZON_NAME_MAPPINGS = {
	[EnumAmazonMarketplace.All]		: 'All Marketplaces',
	[EnumAmazonMarketplace.COM]		: 'COM - United States',
	[EnumAmazonMarketplace.CO_UK]	: 'CO.UK - United Kingdom',
	[EnumAmazonMarketplace.DE]		: 'DE - Germany',
	[EnumAmazonMarketplace.FR]		: 'FR - France',
	[EnumAmazonMarketplace.IT]		: 'IT - Italy',
	[EnumAmazonMarketplace.ES]		: 'ES - Spain',
	[EnumAmazonMarketplace.JP]		: 'JP - Japan',

	[EnumAmazonMarketplace.NL]		: 'NL - Netherlands',
	[EnumAmazonMarketplace.PL]		: 'PL - Poland',
	[EnumAmazonMarketplace.SE]		: 'SE - Sweden',
	[EnumAmazonMarketplace.CA]		: 'CA - Canada',
	[EnumAmazonMarketplace.AU]		: 'AU - Australia',
	[EnumAmazonMarketplace.MX]		: 'MX - Mexico',

	[EnumAmazonProduct.All]			: 'All Products',
	[EnumAmazonProduct.Shirt]		: 'T-Shirt',
	[EnumAmazonProduct.Hoodie]		: 'Hoodie',
	[EnumAmazonProduct.PopSocket]	: 'PopSockets',
	[EnumAmazonProduct.PhoneCase]	: 'Phone Case',
	[EnumAmazonProduct.KDP]			: 'KDP',

	[EnumAmazonProduct.PremiumShirt]: 'Premium Shirt',
	[EnumAmazonProduct.VNeck]		: 'V-Neck',
	[EnumAmazonProduct.TankTop]		: 'Tank Top',
	[EnumAmazonProduct.LongSleeve]	: 'Long Sleeve',
	[EnumAmazonProduct.Raglan]		: 'Raglan',
	[EnumAmazonProduct.Sweatshirt]	: 'Sweatshirt',
	[EnumAmazonProduct.ToteBag]		: 'Tote Bag',
	[EnumAmazonProduct.ThrowPillow]	: 'Throw Pillow',

	[EnumAmazonSorting.Featured]	: 'Featured',
	[EnumAmazonSorting.PriceAsc]	: 'Price: Low to High',
	[EnumAmazonSorting.PriceDesc]	: 'Price: High to Low',
	[EnumAmazonSorting.ReviewDesc]	: 'Reviews',
	[EnumAmazonSorting.Newest]		: 'Newest'
};
