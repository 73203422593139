import { ISparkSidebarSubMenu } from '@domains/frontend/templates/spark';

import { EnumSidebarPage } from '../../sidebar-pages.interface';


/**------------------------------------------------------
 * Shared Pages
 */
export const SHARED_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.Home,
		label		: 'Home',
		routePath	: '/'
	},
	{
		id 			: EnumSidebarPage.Tutorials,
		label		: 'Tutorials',
		routePath	: '/tutorials'
	},
	{
		id 			: EnumSidebarPage.Support,
		label		: 'Support',
		routePath	: '/support'
	}
];
