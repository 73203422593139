/**------------------------------------------------------
 * Large Language Models
 * ---------------------
 * Overview of all models: https://platform.openai.com/docs/models
 */
export enum EnumAiModel {
	Gpt3_5Turbo = 'gpt-3.5-turbo-1106',
	Gpt4		= 'gpt-4',
	Gpt4Turbo	= 'gpt-4-turbo',
	Gpt4o		= 'gpt-4o'
}


/**------------------------------------------------------
 * Names of the Models
 */
export const AI_MODEL_NAMES: Record<EnumAiModel, IAiModel> = {
	[EnumAiModel.Gpt3_5Turbo]	: {
		model		: EnumAiModel.Gpt3_5Turbo,
		name		: 'GPT-3.5 Turbo',
		description	: `GPT-3.5 Turbo is an advanced language model developed by OpenAI, offering remarkable capabilities in natural language understanding and generation. It's an upgraded version of the renowned GPT-3 model, optimized for a wide range of applications.`
	},
	[EnumAiModel.Gpt4] 		: {
		model		: EnumAiModel.Gpt4,
		name		: 'GPT-4',
		description	: `GPT-4 is likely an even more advanced and powerful iteration of the GPT series of language models developed by OpenAI. It is expected to have more parameters, improved natural language understanding, and enhanced text generation capabilities compared to its predecessors, such as GPT-3. This model is designed to handle an even broader range of language-related tasks, from text generation and translation to summarization and more. It represents a significant step forward in the field of AI language models.`
	},
	[EnumAiModel.Gpt4Turbo] : {
		model		: EnumAiModel.Gpt4Turbo,
		name		: 'GPT-4 Turbo',
		description	: `GPT-4 Turbo is an upgraded and highly efficient version of the GPT-4 model. It is optimized for faster response times and better performance in real-time applications. This version offers enhanced cost-effectiveness and is designed to be more accessible for developers and businesses seeking to integrate advanced AI language capabilities into their products and services. GPT-4 Turbo retains the versatility and accuracy of GPT-4 while delivering faster results, making it a compelling choice for interactive and dynamic language-related tasks.`
	},
	[EnumAiModel.Gpt4o] : {
		model		: EnumAiModel.Gpt4o,
		name		: 'GPT-4o',
		description	: `GPT-4o has the ability to understand text and images. This version offers quicker speed.`
	}
};

export interface IAiModel {
	model		: EnumAiModel;
	name		: string;
	description	: string;
}
