import { ISparkSidebarSubMenu } from '@domains/frontend/templates/spark';

import { EnumSidebarPage } from '../../sidebar-pages.interface';


/**------------------------------------------------------
 * Print On Demand
 */
export const POD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.UploadPodStatistics,
		label		: 'Statistics',
		routePath	: '/upload/pod/statistics'
	},
	{
		id 			: EnumSidebarPage.UploadPodDesignManagement,
		label		: 'Designs',
		routePath	: '/upload/pod/design-management'
	},
	{
		id 			: EnumSidebarPage.UploadPodEdit,
		label		: 'Edit',
		info		: 'AI Powered',
		routePath	: '/upload/pod/edit'
	},
	{
		id 			: EnumSidebarPage.UploadAiTemplates,
		label		: 'AI Templates',
		info		: 'AI Powered',
		routePath	: '/upload/pod/ai-templates'
	}
	/* {
		id 			: EnumSidebarPage.UploadPodMbaManager,
		label		: 'MBA Manager',
		routePath	: '',
	},
	{
		id 			: EnumSidebarPage.UploadPodProfileManager,
		label		: 'Profile Manager',
		routePath	: '',
	},
	{
		id 			: EnumSidebarPage.UploadPodSuggestionManager,
		label		: 'Suggestion Manager',
		routePath	: '',
	},
	{
		label		: '_Test',
		routePath	: '/upload/pod/test',
	}, */
];


/**------------------------------------------------------
 * Kindle Direct Publishing
 */
export const KDP_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.UploadKdpEditUpload,
		label		: 'Edit & Upload',
		routePath	: '/upload/kdp/edit-and-upload'
	}
	/* {
		id 			: EnumSidebarPage.UploadKdpSalesStatistics,
		label		: 'Sales & Statistics',
		routePath	: '',
	},
	{
		id 			: EnumSidebarPage.UploadKdpBooksDesigns,
		label		: 'Books & Designs',
		routePath	: '',
	},
	{
		id 			: EnumSidebarPage.UploadKdpManuscripts,
		label		: 'Manuscripts',
		routePath	: '',
	},
	{
		id 			: EnumSidebarPage.UploadKdpEdit,
		label		: 'Edit',
		routePath	: '/upload/kdp/edit',
	},
	{
		id 			: EnumSidebarPage.UploadKdpProfileManager,
		label		: 'Profile Manager',
		routePath	: '',
	},
	{
		id 			: EnumSidebarPage.UploadKdpSuggestionManager,
		label		: 'Suggestion Manager',
		routePath	: '',
	},
	{
		id 			: EnumSidebarPage.UploadKdpBookGenerator,
		label		: 'Book Generator',
		routePath	: '',
	}, */
];


/**------------------------------------------------------
 * Upload Tool / Magic Uploader
 */
export const UPLOAD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.UploadSchedule,
		label		: 'Upload Schedule',
		routePath	: ''
	},
	{
		id 			: EnumSidebarPage.UploadAccountsTemplates,
		label		: 'Accounts & Templates',
		routePath	: '/upload/upload/accounts-templates'
	},
	{
		id 			: EnumSidebarPage.UploadSetupPOD,
		label		: 'Setup POD Uploader',
		routePath	: '/upload/upload/setup-pod-uploader'
	},
	{
		id 			: EnumSidebarPage.UploadSetupKDP,
		label		: 'Setup KDP Uploader',
		routePath	: '/upload/upload/setup-kdp-uploader'
	}
	/*
	{
		id 			: EnumSidebarPage.UploadTemplateGroups,
		label		: 'Template Groups',
		routePath	: '/upload/upload/template-groups',
	} */
];


/**------------------------------------------------------
 * Trademarks Upload
 */
export const TM_UPLOAD_POD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		label		: 'TM Report',
		id 			: EnumSidebarPage.UploadPodTmReport,
		routePath	: '/upload/pod/tm-report'
	},
	{
		id 			: EnumSidebarPage.UploadPodTmWhitelist,
		label		: 'TM Whitelist',
		routePath	: '/upload/pod/tm-whitelist'
	},
	{
		id 			: EnumSidebarPage.UploadPodTmBlacklist,
		label		: 'TM Blacklist',
		routePath	: '/upload/pod/tm-blacklist'
	}
];


/**------------------------------------------------------
 * Advertise & Promote
 */
/* const ADVERTISE_UPLOAD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.UploadAdvertiseAmazonDsp,
		label		: 'Amazon DSP',
		routePath	: '',
	},
	{
		id 			: EnumSidebarPage.UploadAdvertiseSocialUploader,
		label		: 'Social Uploader',
		routePath	: '',
	},
]; */


/**------------------------------------------------------
 * Downloads & Image Bundles
 */
/* const DOWNLOADS_UPLOAD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.UploadDownloadsResourceBundles,
		label		: 'Resource Bundles',
		routePath	: '',
	},
	{
		id 			: EnumSidebarPage.UploadDownloadsDesignsVectors,
		label		: 'Designs / Vectors',
		routePath	: '',
	},
]; */
