import { ISparkSidebarSubMenu } from '@domains/frontend/templates/spark';

import { EnumSidebarPage } from '../../sidebar-pages.interface';


/**------------------------------------------------------
 * Research
 */
export const RESEARCH_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.ResearchBestSeller,
		label		: 'Best Sellers',
		routePath	: '/research/research/best-sellers'
	},
	{
		id 			: EnumSidebarPage.ResearchSellerTrends,
		label		: 'Seller Trends',
		routePath	: '/research/research/seller-trends'
	},
	/* {
		id 			: EnumSidebarPage.ResearchSellerHistory,
		label		: 'Seller History',
		routePath	: '/research/research/seller-history',
		disable		: true
	}, */
	{
		id 			: EnumSidebarPage.ResearchDeleted,
		label		: 'Deleted',
		routePath	: '/research/research/deleted-designs'
	},
	{
		id 			: EnumSidebarPage.ResearchRtr,
		label		: 'Real Time Research',
		routePath	: '/research/research/real-time-research'
	},
	{
		id 			: EnumSidebarPage.ResearchNicheAnalysis,
		label		: 'Niche Analysis',
		routePath	: '/research/research/niche-analysis'
	},
	{
		id 			: EnumSidebarPage.ResearchGetInspiredPod,
		label		: 'Get Inspired POD v2',
		routePath	: '/research/research/get-inspired-pod',
		info		: 'Updated'
	},
	{
		id 			: EnumSidebarPage.ResearchBrands,
		label		: 'Official Brands',
		routePath	: '/research/research/brands'
	},
	/* {
		id 			: EnumSidebarPage.ResearchNicheList,
		label		: 'Niche Lists',
		routePath	: '/research/research/niche-lists'
	}, */
	{
		id 			: EnumSidebarPage.ResearchHobbyList,
		label		: 'Hobby List',
		routePath	: '/research/research/hobby-list'
	},
	{
		id 			: EnumSidebarPage.ResearchFavorites,
		label		: 'Design Favorites',
		routePath	: '/research/research/favorites'
	}
];


/**------------------------------------------------------
 * Event Pages (Research)
 */
export const RESEARCH_EVENT_PAGE_LIST: ISparkSidebarSubMenu[] = [
	/* {
		id 			: EnumSidebarPage.EventsEventSellers,
		label		: 'Event Sellers',
		routePath	: '/research/events/event-sellers',
		disable		: true
	}, */
	{
		id 			: EnumSidebarPage.EventsSocialMedia,
		label		: 'Social Media Holidays',
		routePath	: '/research/events/social-media-holidays'
	},
	{
		id 			: EnumSidebarPage.EventsCalendar,
		label		: 'Calendar',
		routePath	: '/research/events/event-calendar'
	}
];


/**------------------------------------------------------
 * Trademark
 */
export const TRADEMARK_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.TrademarkSearch,
		label		: 'TM Search',
		routePath	: '/research/trademark/tm-search'
	},
	{
		id 			: EnumSidebarPage.TrademarkRegistration,
		label		: 'TM Registrations',
		routePath	: '/research/trademark/tm-registrations'
	},
	{
		id 			: EnumSidebarPage.TrademarkWatchlist,
		label		: 'TM Watchlist',
		routePath	: '/research/trademark/tm-watchlist'
	}
];


/**------------------------------------------------------
 * Keyword
 */
export const KEYWORD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.KeywordSearch,
		label		: 'KW Search',
		routePath	: '/research/keyword/kw-search'
	},
	{
		id 			: EnumSidebarPage.KeywordCompetition,
		label		: 'KW Competition',
		routePath	: '/research/keyword/kw-competition'
	},
	{
		id 			: EnumSidebarPage.KeywordHunter,
		label		: 'KW Hunter',
		routePath	: '/research/keyword/kw-hunter'
	},
	{
		id 			: EnumSidebarPage.ProductExplorer,
		label		: 'Product Explorer',
		routePath	: '/research/keyword/product-explorer'
	},
	{
		id 			: EnumSidebarPage.KeywordSuggestions,
		label		: 'KW Suggestions',
		routePath	: '/research/keyword/kw-suggestions'
	},
	{
		id 			: EnumSidebarPage.KeywordWorkbench,
		label		: 'KW Workbench',
		routePath	: '/research/keyword/kw-workbench'
	},
	{
		id 			: EnumSidebarPage.KeywordTagGenerator,
		label		: 'Tag Generator',
		routePath	: '/research/keyword/tag-generator'
	},
	{
		id 			: EnumSidebarPage.KeywordLists,
		label		: 'KW Lists',
		routePath	: '/research/keyword/kw-lists'
	}
];


/**------------------------------------------------------
 * Advertisement Keywords
 */
export const ADVERTISEMENT_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.RankPositioning,
		label		: 'Rank Positioning',
		routePath	: '/research/advertisement/positioning'
	},
	{
		id 			: EnumSidebarPage.AdvertisingSuggestions,
		label		: 'Ad Suggestions',
		routePath	: '/research/advertisement/ad-suggestions'
	},
	{
		id 			: EnumSidebarPage.AdvertisingLists,
		label		: 'Ad Lists',
		routePath	: '/research/advertisement/ad-lists'
	}
];


/**------------------------------------------------------
 * AI Research
 */
export const AI_RESEARCH_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.NicheFinder,
		label		: 'Aurora',
		routePath	: '/research/ai/niche-finder',
		info		: 'AI Powered'
	},
	{
		id 			: EnumSidebarPage.PromptSearch,
		label		: 'Reveal',
		routePath	: '/research/ai/prompt-search',
		info		: 'AI Powered'
	},
	{
		id 			: EnumSidebarPage.QuotesAndPuns,
		label		: 'Mumble',
		routePath	: '/research/ai/quotes-and-puns',
		info		: 'AI Powered'
	}
];


/**------------------------------------------------------
 * Trending Tags
 */
/* const TRENDING_TAG_PAGE_LIST: ISidebarPage[] = [
	{
		id 			: EnumSidebarPage.TrendingTagRedbubble,
		label		: 'Redbubble Tags',
		routePath	: '/research/trending-tags/redbubble',
	},
	{
		id 			: EnumSidebarPage.TrendingTagEtsy,
		label		: 'Etsy Tags',
		routePath	: '/research/trending-tags/etsy',
	},
]; */


/**------------------------------------------------------
 * Designer / Team Management
 */
/* const MANAGEMENT_PAGE_LIST: ISidebarPage[] = [
	{
		id 			: EnumSidebarPage.ManagementTeamDesigner,
		label		: 'Team/Designer',
		routePath	: ''
	},
	{
		id 			: EnumSidebarPage.ManagementTasks,
		label		: 'Tasks',
		routePath	: '' },
	},
	{
		id 			: EnumSidebarPage.ManagementInvoices,
		label		: 'Invoices',
		routePath	: ''
	},
	{
		id 			: EnumSidebarPage.ManagementDesignRequests,
		label		: 'Design Requests',
		routePath	: ''
	},
	{
		id 			: EnumSidebarPage.ManagementDesignWork,
		label		: 'Design Work',
		routePath	: ''
	},
	{
		id 			: EnumSidebarPage.ManagementFindDesigners,
		label		: 'Find Designers',
		routePath	: ''
	},
]; */


/**------------------------------------------------------
 * Sales & Statistics
 */
/* const STATISTICS_PAGE_LIST: ISidebarPage[] = [
	{
		id 			: EnumSidebarPage.StatisticsUploads,
		label		: 'Uploads',
		routePath	: ''
	},
	{
		id 			: EnumSidebarPage.StatisticsEarnings,
		label		: 'Earnings',
		routePath	: '',
	}
]; */
