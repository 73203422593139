import { EnumLanguage } from '@libs/constants';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_UPLOAD_SETTING_LIMITS: IFlyingUploadSettingsConfig = {
	API_KEY_LENGTH			: 100,
	GLOBAL_TITLE_MAX		: 120,
	GLOBAL_DESCRIPTION_MAX	: 2000
};

interface IFlyingUploadSettingsConfig {
	API_KEY_LENGTH			: number;
	GLOBAL_TITLE_MAX		: number;
	GLOBAL_DESCRIPTION_MAX	: number;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingUploadSettingTranslation {
	Google			= 'google_translate',
	DeepL			= 'deepl_translate'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IFlyingUploadSettings {
	printOnDemand	: IFlyingPrintOnDemand;
	aiListing		: IFlyingAiListingGenerator;
}


/**------------------------------------------------------
 * POD Edit
 */
export interface IFlyingPrintOnDemand {
	mainLanguage	: EnumLanguage;
	editLanguages	: EnumLanguage[];
	smartTagging	: boolean;
	smartTranslate	: boolean;
	transferOptions	: boolean;
	cutOverLength	: IFlyingPodCutOverLength;
	translation		: IFlyingPodTranslate;
}

export interface IFlyingPodCutOverLength {
	isActive		: boolean;
	globalTitle		: number;
	globalDesc		: number;
}

export interface IFlyingPodTranslate {
	type			: EnumFlyingUploadSettingTranslation;
	deeplApiKey		: string;
}


/**------------------------------------------------------
 * AI Listing
 */
export interface IFlyingAiListingGenerator {
	openAiKey		: string;					// the API-Key of the OpenAI account of the user
	global			: IFlyingGlobalAiListing;
	mba				: IFlyingMbaAiListing;
	displate		: IFlyingDisplateAiListing;
}

export interface IFlyingGlobalAiListing {
	isActive 		: boolean;
	languages		: EnumLanguage[];
	includes 		: {
		title		: boolean;
		description	: boolean;
		keywords	: boolean;
	};
}

export interface IFlyingMbaAiListing {
	isActive 		: boolean;
	languages		: EnumLanguage[];
	includes 		: {
		brand		: boolean;
		title		: boolean;
		bullet1		: boolean;
		bullet2		: boolean;
		description	: boolean;
	};
}

export interface IFlyingDisplateAiListing {
	isActive		: boolean;
	includes		: {
		title		: boolean;
		description	: boolean;
		keywords	: boolean;
	};
}


/**------------------------------------------------------
 * Schema
 */
export class FlyingUploadSettingsSchema implements ValidatorSchema<IFlyingUploadSettings> {
	printOnDemand = {
		mainLanguage		: Validator.Enum(EnumLanguage).isNotEmpty(),
		editLanguages		: Validator.Array().each(Validator.Enum(EnumLanguage)),
		smartTagging		: Validator.Boolean(),
		smartTranslate		: Validator.Boolean(),
		transferOptions		: Validator.Boolean(),
		cutOverLength		: {
			isActive		: Validator.Boolean(),
			globalTitle		: Validator.Number().isGreaterThanOrEqual(0).isLessThanOrEqual(FLYING_UPLOAD_SETTING_LIMITS.GLOBAL_TITLE_MAX),
			globalDesc		: Validator.Number().isGreaterThanOrEqual(0).isLessThanOrEqual(FLYING_UPLOAD_SETTING_LIMITS.GLOBAL_DESCRIPTION_MAX)
		},
		translation			: {
			type			: Validator.Enum(EnumFlyingUploadSettingTranslation),
			deeplApiKey    	: Validator.String().isMaxLength(FLYING_UPLOAD_SETTING_LIMITS.API_KEY_LENGTH)
		}
	};
	aiListing = {
		openAiKey			: Validator.String().isMaxLength(FLYING_UPLOAD_SETTING_LIMITS.API_KEY_LENGTH),
		global				: {
			isActive 		: Validator.Boolean(),
			languages		: Validator.Array().each(Validator.Enum(EnumLanguage)),
			includes 		: {
				title		: Validator.Boolean(),
				description	: Validator.Boolean(),
				keywords	: Validator.Boolean()
			}
		},
		mba			: {
			isActive 		: Validator.Boolean(),
			languages		: Validator.Array().each(Validator.Enum(EnumLanguage)),
			includes 		: {
				brand		: Validator.Boolean(),
				title		: Validator.Boolean(),
				bullet1		: Validator.Boolean(),
				bullet2		: Validator.Boolean(),
				description	: Validator.Boolean()
			}
		},
		displate			: {
			isActive		: Validator.Boolean(),
			includes		: {
				title		: Validator.Boolean(),
				description	: Validator.Boolean(),
				keywords	: Validator.Boolean()
			}
		}
	};
}
