/* eslint-disable @typescript-eslint/typedef */
import { EnumMbaColor, EnumPlatform } from '@apps/flying/shared';


/**------------------------------------------------------
 * Logos
 */
export const LOGO_LINKS = {
	RESEARCH_LOGO_HIGH 	: 'assets/research/logos/research-logo-high.svg',
	RESEARCH_LOGO_WIDE 	: 'assets/research/logos/research-logo-wide.svg',
	RESEARCH_LOGO	 	: 'assets/research/logos/research-logo.svg',
	KEYWORD_LOGO_WIDE 	: 'assets/research/logos/keywords-logo-wide.svg',
	UPLOAD_LOGO_HIGH 	: 'assets/upload/logos/upload-logo-high.svg',
	UPLOAD_LOGO_WIDE 	: 'assets/upload/logos/upload-logo-wide.svg',
	UPLOAD_LOGO 		: 'assets/upload/logos/upload-logo.svg'
};


/**------------------------------------------------------
 * Shared Links
 */
export const SHARED_IMAGE_LINKS = {
	LASSO_SOLID 		: 'assets/shared/cursors/lasso-solid.svg',		// USED IN SCSS FILE!!!
	PAGE_NOT_FOUND 		: 'assets/shared/errors/page-not-found.png',
	CLOUD 				: 'assets/shared/errors/cloud.png',
	PAYMENT_PAYPAL		: 'assets/shared/payment-logos/paypal.png',
	PAYMENT_VISA		: 'assets/shared/payment-logos/visa.png',
	PAYMENT_MASTERCARD	: 'assets/shared/payment-logos/mastercard.png',
	DASHBOARD_CARDS		: {
		CARD_COMMUNITY 		: 'assets/shared/cards/card-community.jpg',
		CARD_RESEARCH_POD 	: 'assets/shared/cards/card-research-pod.jpg',
		CARD_TRADEMARKS 	: 'assets/shared/cards/card-trademarks.jpg',
		CARD_TUTORIALS 		: 'assets/shared/cards/card-tutorials.jpg',
		CARD_STATISTICS		: 'assets/shared/cards/card-statistics.jpg'
	},
	RECOMMENDATIONS 	: {
		RYAN_HOGUE			: 'assets/shared/testimonials/ryan-hogue.png',
		DANIEL_GAISWINKLER	: 'assets/shared/testimonials/daniel-gaiswinkler.png',
		PATRICK				: 'assets/shared/testimonials/patrick.png',
		NICO				: 'assets/shared/testimonials/nico.png',
		LEON_CHAUDHARI		: 'assets/shared/testimonials/leon-chaudhari.png'
	},
	TUTORIALS			: {
		PLATFORMS			: 'assets/shared/tutorials/platforms.jpg',
		DESIGNING			: 'assets/shared/tutorials/designing.jpg',
		RESEARCH			: 'assets/shared/tutorials/research.jpg',
		KEYWORDS   			: 'assets/shared/tutorials/keywords.jpg',
		UPLOAD   			: 'assets/shared/tutorials/upload.jpg',
		WELCOME				: 'assets/shared/tutorials/welcome.jpg',
		POD_UPLOAD   		: 'assets/shared/tutorials/pod-upload.jpg',
		KDP_UPLOAD   		: 'assets/shared/tutorials/kdp-upload.jpg',
		TRADEMARKS			: 'assets/shared/tutorials/trademarks.jpg',
		DESIGN_MANAGEMENT	: 'assets/shared/tutorials/design-management.jpg'
	}
};


/**------------------------------------------------------
 * Research Links
 */
export const RESEARCH_IMAGE_LINKS = {
	RATINGS			: {
		RATING_VALUE_1		: 'assets/research/ratings/rating-1.png',
		RATING_VALUE_2		: 'assets/research/ratings/rating-2.png',
		RATING_VALUE_3		: 'assets/research/ratings/rating-3.png',
		RATING_VALUE_4		: 'assets/research/ratings/rating-4.png',
		RATING_VALUE_5		: 'assets/research/ratings/rating-5.png',
		RATING_VALUE_6		: 'assets/research/ratings/rating-6.png'
	},
	NICHE_ANALYSIS	: {
		BANNER_BLACK 		: 'assets/research/banners/niche-analysis-black.svg',
		BANNER_WHITE 		: 'assets/research/banners/niche-analysis-white.svg'
	}
};


/**------------------------------------------------------
 * Upload Links
 */
export const UPLOAD_IMAGE_LINKS = {
	PLATFORM_LOGOS: {
		DISPLATE			: 'assets/upload/platforms/logos/displate.png',
		FINE_ART_AMERICA	: 'assets/upload/platforms/logos/fine-art-america.png',
		KDP_KINDLE			: 'assets/upload/platforms/logos/kindle-direct-publishing.png',
		MERCH_BY_AMAZON		: 'assets/upload/platforms/logos/merch-by-amazon.png',
		PRINTFUL			: 'assets/upload/platforms/logos/printful.png',
		PRINTIFY			: 'assets/upload/platforms/logos/printify.png',
		REDBUBBLE			: 'assets/upload/platforms/logos/redbubble.png',
		SHIRTEE				: 'assets/upload/platforms/logos/shirtee.png',
		SOCIETY6			: 'assets/upload/platforms/logos/society6.png',
		SPREADSHIRT_COM		: 'assets/upload/platforms/logos/spreadshirt-com.png',
		SPREADSHIRT_EU		: 'assets/upload/platforms/logos/spreadshirt-eu.png',
		TEEPUBLIC			: 'assets/upload/platforms/logos/teepublic.png',
		TEESPRING			: 'assets/upload/platforms/logos/teespring.png',
		TEEZILY				: 'assets/upload/platforms/logos/teezily.png',
		THREADLESS			: 'assets/upload/platforms/logos/threadless.png',
		TOSTADORA			: 'assets/upload/platforms/logos/tostadora.png',
		ZAZZLE				: 'assets/upload/platforms/logos/zazzle.png'
	},
	PLATFORM_ICONS: {
		DISPLATE			: 'assets/upload/platforms/icons/displate.png',
		FINE_ART_AMERICA	: 'assets/upload/platforms/icons/fine-art-america.png',
		KDP_KINDLE			: 'assets/upload/platforms/icons/kindle-direct-publishing.png',
		MERCH_BY_AMAZON		: 'assets/upload/platforms/icons/merch-by-amazon.png',
		PRINTFUL			: 'assets/upload/platforms/icons/printful.png',
		PRINTIFY			: 'assets/upload/platforms/icons/printify.png',
		REDBUBBLE			: 'assets/upload/platforms/icons/redbubble.png',
		SHIRTEE				: 'assets/upload/platforms/icons/shirtee.png',
		SOCIETY6			: 'assets/upload/platforms/icons/society6.png',
		SPREADSHIRT_COM		: 'assets/upload/platforms/icons/spreadshirt-com.png',
		SPREADSHIRT_EU		: 'assets/upload/platforms/icons/spreadshirt-eu.png',
		TEEPUBLIC			: 'assets/upload/platforms/icons/teepublic.png',
		TEESPRING			: 'assets/upload/platforms/icons/teespring.png',
		TEEZILY				: 'assets/upload/platforms/icons/teezily.png',
		THREADLESS			: 'assets/upload/platforms/icons/threadless.png',
		TOSTADORA			: 'assets/upload/platforms/icons/tostadora.png',
		ZAZZLE				: 'assets/upload/platforms/icons/zazzle.png'
	},
	SEARCH_LINKS: {
		AMAZON				: 'assets/upload/platforms/search-links/amazon.png',
		ETSY				: 'assets/upload/platforms/search-links/etsy.png',
		FREEPICK			: 'assets/upload/platforms/search-links/freepick.png',
		PEXELS				: 'assets/upload/platforms/search-links/pexels.png',
		PIXABAY				: 'assets/upload/platforms/search-links/pixabay.png',
		SHUTTERSTOCK		: 'assets/upload/platforms/search-links/shutterstock.png',
		VEXELS				: 'assets/upload/platforms/search-links/vexels.png',
		INSTAGRAM			: 'assets/upload/platforms/search-links/instagram.png',
		PINTEREST			: 'assets/upload/platforms/search-links/pinterest.png',
		TWITTER				: 'assets/upload/platforms/search-links/twitter.png',
		SOCIAL_9GAG			: 'assets/upload/platforms/search-links/9gag.png',
		GOOGLE				: 'assets/upload/platforms/search-links/google.png',
		GOOGLE_IMAGES		: 'assets/upload/platforms/search-links/google-images.png',
		GOOGLE_TRENDS		: 'assets/upload/platforms/search-links/google-trends.png',
		WIKIPEDIA			: 'assets/upload/platforms/search-links/wikipedia.png'
	},
	MBA_COLOR_ICONS: {
		DARK_HEATHER			: 'assets/upload/platforms/resources/mba-color-icons/dark-heather.png',
		HEATHER_BLUE			: 'assets/upload/platforms/resources/mba-color-icons/heather-blue.png',
		HEATHER_GREY			: 'assets/upload/platforms/resources/mba-color-icons/heather-grey.png',
		BLACK_ATHLETIC_HEATHER	: 'assets/upload/platforms/resources/mba-color-icons/black-athletic-heather.svg',
		BLACK_WHITE				: 'assets/upload/platforms/resources/mba-color-icons/black-white.svg',
		DARK_HEATHER_WHITE		: 'assets/upload/platforms/resources/mba-color-icons/dark-heather-white.svg',
		NAVY_ATHLETIC_HEATHER	: 'assets/upload/platforms/resources/mba-color-icons/navy-athletic-heather.svg',
		NAVY_WHITE				: 'assets/upload/platforms/resources/mba-color-icons/navy-white.svg',
		RED_WHITE				: 'assets/upload/platforms/resources/mba-color-icons/red-white.svg',
		ROYAL_BLUE_WHITE		: 'assets/upload/platforms/resources/mba-color-icons/royal-blue-white.svg',
		PURPLE_HEATHER			: 'assets/upload/platforms/resources/mba-color-icons/purple-heather.png',
		RED_HEATHER				: 'assets/upload/platforms/resources/mba-color-icons/red-heather.png',
		OLIVE_HEATHER			: 'assets/upload/platforms/resources/mba-color-icons/olive-heather.png',
		PINK_HEATHER			: 'assets/upload/platforms/resources/mba-color-icons/pink-heather.png'
	},
	MBA_PRODUCT_IMAGES: {
		IPHONE_CASES_DARK		: 'assets/upload/platforms/resources/mba-product-images/iphone-cases-dark.png',
		IPHONE_CASES_DIMMED_DARK: 'assets/upload/platforms/resources/mba-product-images/iphone-cases-dimmed-dark.png',
		IPHONE_CASES			: 'assets/upload/platforms/resources/mba-product-images/iphone-cases.png',
		LONG_SLEEVE_SHIRT		: 'assets/upload/platforms/resources/mba-product-images/long-sleeve-shirt.png',
		POPSOCKETS_GRIP_DARK	: 'assets/upload/platforms/resources/mba-product-images/popsockets-grip-dark.png',
		POPSOCKETS_GRIP_DIMMED_DARK: 'assets/upload/platforms/resources/mba-product-images/popsockets-grip-dimmed-dark.png',
		POPSOCKETS_GRIP			: 'assets/upload/platforms/resources/mba-product-images/popsockets-grip.png',
		PREMIUM_SHIRT			: 'assets/upload/platforms/resources/mba-product-images/premium-shirt.png',
		PULLOVER_HOODIE			: 'assets/upload/platforms/resources/mba-product-images/pullover-hoodie.png',
		RAGLAN					: 'assets/upload/platforms/resources/mba-product-images/raglan.png',
		SAMSUNG_CASES_DARK		: 'assets/upload/platforms/resources/mba-product-images/samsung-cases-dark.png',
		SAMSUNG_CASES_DIMMED_DARK: 'assets/upload/platforms/resources/mba-product-images/samsung-cases-dimmed-dark.png',
		SAMSUNG_CASES			: 'assets/upload/platforms/resources/mba-product-images/samsung-cases.png',
		STANDARD_SHIRT			: 'assets/upload/platforms/resources/mba-product-images/standard-shirt.png',
		SWEATSHIRT				: 'assets/upload/platforms/resources/mba-product-images/sweatshirt.png',
		TANK_TOP				: 'assets/upload/platforms/resources/mba-product-images/tank-top.png',
		THROW_PILLOWS			: 'assets/upload/platforms/resources/mba-product-images/throw-pillows.png',
		THROW_PILLOWS_DARK		: 'assets/upload/platforms/resources/mba-product-images/throw-pillows-dark.png',
		THROW_PILLOWS_DIMMED_DARK: 'assets/upload/platforms/resources/mba-product-images/throw-pillows-dimmed-dark.png',
		TOTE_BAG_DARK			: 'assets/upload/platforms/resources/mba-product-images/tote-bag-dark.png',
		TOTE_BAG_DIMMED_DARK	: 'assets/upload/platforms/resources/mba-product-images/tote-bag-dimmed-dark.png',
		TOTE_BAG				: 'assets/upload/platforms/resources/mba-product-images/tote-bag.png',
		VNECK_SHIRT				: 'assets/upload/platforms/resources/mba-product-images/vneck-shirt.png',
		ZIP_HOODIE				: 'assets/upload/platforms/resources/mba-product-images/zip-hoodie.png'
	},
	MBA_PRODUCT_ICONS: {
		DEFAULT	: {
			IPHONE_CASES		: 'assets/upload/platforms/resources/mba-product-icons/default/iphone-cases.svg',
			LONG_SLEEVE_SHIRT	: 'assets/upload/platforms/resources/mba-product-icons/default/long-sleeve-shirt.svg',
			POPSOCKETS_GRIP		: 'assets/upload/platforms/resources/mba-product-icons/default/popsockets-grip.svg',
			PREMIUM_SHIRT		: 'assets/upload/platforms/resources/mba-product-icons/default/premium-shirt.svg',
			PULLOVER_HOODIE		: 'assets/upload/platforms/resources/mba-product-icons/default/pullover-hoodie.svg',
			RAGLAN				: 'assets/upload/platforms/resources/mba-product-icons/default/raglan.svg',
			SAMSUNG_CASES		: 'assets/upload/platforms/resources/mba-product-icons/default/samsung-cases.svg',
			STANDARD_SHIRT		: 'assets/upload/platforms/resources/mba-product-icons/default/standard-shirt.svg',
			SWEATSHIRT			: 'assets/upload/platforms/resources/mba-product-icons/default/sweatshirt.svg',
			TANK_TOP			: 'assets/upload/platforms/resources/mba-product-icons/default/tank-top.svg',
			THROW_PILLOWS		: 'assets/upload/platforms/resources/mba-product-icons/default/throw-pillows.svg',
			TOTE_BAG			: 'assets/upload/platforms/resources/mba-product-icons/default/tote-bag.svg',
			VNECK_SHIRT			: 'assets/upload/platforms/resources/mba-product-icons/default/vneck-shirt.svg',
			ZIP_HOODIE			: 'assets/upload/platforms/resources/mba-product-icons/default/zip-hoodie.svg'
		},
		FILLED 	: {
			IPHONE_CASES		: 'assets/upload/platforms/resources/mba-product-icons/filled/iphone-cases.svg',
			LONG_SLEEVE_SHIRT	: 'assets/upload/platforms/resources/mba-product-icons/filled/long-sleeve-shirt.svg',
			POPSOCKETS_GRIP		: 'assets/upload/platforms/resources/mba-product-icons/filled/popsockets-grip.svg',
			PREMIUM_SHIRT		: 'assets/upload/platforms/resources/mba-product-icons/filled/premium-shirt.svg',
			PULLOVER_HOODIE		: 'assets/upload/platforms/resources/mba-product-icons/filled/pullover-hoodie.svg',
			RAGLAN				: 'assets/upload/platforms/resources/mba-product-icons/filled/raglan.svg',
			SAMSUNG_CASES		: 'assets/upload/platforms/resources/mba-product-icons/filled/samsung-cases.svg',
			STANDARD_SHIRT		: 'assets/upload/platforms/resources/mba-product-icons/filled/standard-shirt.svg',
			SWEATSHIRT			: 'assets/upload/platforms/resources/mba-product-icons/filled/sweatshirt.svg',
			TANK_TOP			: 'assets/upload/platforms/resources/mba-product-icons/filled/tank-top.svg',
			THROW_PILLOWS		: 'assets/upload/platforms/resources/mba-product-icons/filled/throw-pillows.svg',
			TOTE_BAG			: 'assets/upload/platforms/resources/mba-product-icons/filled/tote-bag.svg',
			VNECK_SHIRT			: 'assets/upload/platforms/resources/mba-product-icons/filled/vneck-shirt.svg',
			ZIP_HOODIE			: 'assets/upload/platforms/resources/mba-product-icons/filled/zip-hoodie.svg'
		}
	}
};

export const UPLOAD_MBA_COLOR_ICONS: Partial<Record<EnumMbaColor, string>> = {
	[EnumMbaColor.DarkHeather]			: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.DARK_HEATHER,
	[EnumMbaColor.HeatherBlue]			: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.HEATHER_BLUE,
	[EnumMbaColor.HeatherGrey]			: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.HEATHER_GREY,
	[EnumMbaColor.BlackAthleticHeather]	: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.BLACK_ATHLETIC_HEATHER,
	[EnumMbaColor.BlackWhite]			: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.BLACK_WHITE,
	[EnumMbaColor.DarkHeatherWhite]		: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.DARK_HEATHER_WHITE,
	[EnumMbaColor.NavyAthleticHeather]	: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.NAVY_ATHLETIC_HEATHER,
	[EnumMbaColor.NavyWhite]			: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.NAVY_WHITE,
	[EnumMbaColor.RedWhite]				: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.RED_WHITE,
	[EnumMbaColor.RoyalBlueWhite]		: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.ROYAL_BLUE_WHITE,
	[EnumMbaColor.PurpleHeather]		: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.PURPLE_HEATHER,
	[EnumMbaColor.RedHeather]			: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.RED_HEATHER,
	[EnumMbaColor.OliveHeather]			: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.OLIVE_HEATHER,
	[EnumMbaColor.PinkHeather]			: UPLOAD_IMAGE_LINKS.MBA_COLOR_ICONS.PINK_HEATHER
};

export const UPLOAD_PLATFORM_LINKS: Record<EnumPlatform, IUploadPlatformLogo> = {
	[EnumPlatform.Displate]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.DISPLATE,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.DISPLATE
	},
	[EnumPlatform.FineArtAmerica]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.FINE_ART_AMERICA,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.FINE_ART_AMERICA
	},
	[EnumPlatform.MBA]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.MERCH_BY_AMAZON,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.MERCH_BY_AMAZON
	},
	[EnumPlatform.KDP]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.KDP_KINDLE,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.KDP_KINDLE
	},
	[EnumPlatform.Printful]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.PRINTFUL,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.PRINTFUL
	},
	[EnumPlatform.Printify]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.PRINTIFY,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.PRINTIFY
	},
	[EnumPlatform.Redbubble]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.REDBUBBLE,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.REDBUBBLE
	},
	[EnumPlatform.Shirtee]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.SHIRTEE,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.SHIRTEE
	},
	[EnumPlatform.Society6]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.SOCIETY6,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.SOCIETY6
	},
	[EnumPlatform.SpreadshirtUS]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.SPREADSHIRT_COM,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.SPREADSHIRT_COM
	},
	[EnumPlatform.SpreadshirtEU]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.SPREADSHIRT_EU,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.SPREADSHIRT_EU
	},
	[EnumPlatform.Teepublic]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.TEEPUBLIC,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.TEEPUBLIC
	},
	[EnumPlatform.Teespring]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.TEESPRING,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.TEESPRING
	},
	[EnumPlatform.Tostadora]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.TOSTADORA,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.TOSTADORA
	},
	[EnumPlatform.Zazzle]: {
		platformIcon	: UPLOAD_IMAGE_LINKS.PLATFORM_ICONS.ZAZZLE,
		platformLogo	: UPLOAD_IMAGE_LINKS.PLATFORM_LOGOS.ZAZZLE
	}
};

interface IUploadPlatformLogo {
	platformIcon 	: string;
	platformLogo 	: string;
}


/**------------------------------------------------------
 * Profile Images
 */
export const PROFILE_PICTURE_LINKS = {
	PROFILE_1		: 'assets/shared/profile/profile1.jpg',
	PROFILE_2		: 'assets/shared/profile/profile2.jpg',
	PROFILE_3		: 'assets/shared/profile/profile3.jpg',
	PROFILE_4		: 'assets/shared/profile/profile4.jpg',
	PROFILE_5		: 'assets/shared/profile/profile5.jpg',
	PROFILE_6		: 'assets/shared/profile/profile6.jpg',
	PROFILE_7		: 'assets/shared/profile/profile7.jpg',
	PROFILE_8		: 'assets/shared/profile/profile8.jpg',
	PROFILE_9		: 'assets/shared/profile/profile9.jpg',
	PROFILE_10		: 'assets/shared/profile/profile10.jpg',
	PROFILE_11		: 'assets/shared/profile/profile11.jpg',
	PROFILE_12		: 'assets/shared/profile/profile12.jpg',
	PROFILE_13		: 'assets/shared/profile/profile13.jpg',
	PROFILE_14		: 'assets/shared/profile/profile14.jpg',
	PROFILE_15		: 'assets/shared/profile/profile15.jpg',
	PROFILE_16		: 'assets/shared/profile/profile16.jpg'
};


/**------------------------------------------------------
 * Mascot Images
 */
export const MASCOT_IMG_LINKS = {
	BIRTHDAY 			: 'assets/shared/mascot/mascot-1th-birthday.png',
	ALMOST_CRYING 		: 'assets/shared/mascot/mascot-almost-crying.png',
	BUSINESS 			: 'assets/shared/mascot/mascot-business.png',
	CHRISTMAS_CANDY 	: 'assets/shared/mascot/mascot-christmas-candy.png',
	CHRISTMAS_TREE 		: 'assets/shared/mascot/mascot-christmas-tree.png',
	COMPUTER_REPAIR 	: 'assets/shared/mascot/mascot-computer-repair.png',
	DETECTIVE 			: 'assets/shared/mascot/mascot-detective.png',
	DUBBING_LEFT 		: 'assets/shared/mascot/mascot-dubbing-left.png',
	FLYING_CLOUD 		: 'assets/shared/mascot/mascot-flying-cloud.png',
	HANDSTAND 			: 'assets/shared/mascot/mascot-handstand.png',
	HAPPY 				: 'assets/shared/mascot/mascot-happy.png',
	IN_LOVE 			: 'assets/shared/mascot/mascot-in-love.png',
	LIKE 				: 'assets/shared/mascot/mascot-like.png',
	MEDITATE 			: 'assets/shared/mascot/mascot-meditate.png',
	MEGAPHONE 			: 'assets/shared/mascot/mascot-megaphone.png',
	MOTIVATION 			: 'assets/shared/mascot/mascot-motivation.png',
	PACKAGES 			: 'assets/shared/mascot/mascot-packages.png',
	PLUG_CABLE 			: 'assets/shared/mascot/mascot-plug-cables.png',
	PROGRAMMING 		: 'assets/shared/mascot/mascot-programming.png',
	SHOCKED 			: 'assets/shared/mascot/mascot-shocked.png',
	SHOW_INFO 			: 'assets/shared/mascot/mascot-show-info.png',
	SHOW_PLAY_VIDEO 	: 'assets/shared/mascot/mascot-show-play-video.png',
	SHY_GIRL 			: 'assets/shared/mascot/mascot-shy-girl.png',
	SUPER_MECHANIC 		: 'assets/shared/mascot/mascot-super-mechanic.png',
	TEACH 				: 'assets/shared/mascot/mascot-teach.png',
	THINKING 			: 'assets/shared/mascot/mascot-thinking.png',
	VALENTINE 			: 'assets/shared/mascot/mascot-valentine.png',
	VAULT 				: 'assets/shared/mascot/mascot-vault.png',
	WISE_MAN 			: 'assets/shared/mascot/mascot-wise-man.png',
	ANNOYED 			: 'assets/shared/mascot/mascot-annoyed.png',
	ASTRONAUT_A 		: 'assets/shared/mascot/mascot-astronaut-a.png',
	ASTRONAUT_B 		: 'assets/shared/mascot/mascot-astronaut-b.png',
	SPACE_COFFEE 		: 'assets/shared/mascot/mascot-astronaut-coffee.png',
	AT_SERVER 			: 'assets/shared/mascot/mascot-at-server.png',
	DELIVERY 			: 'assets/shared/mascot/mascot-delivery.png',
	DROID 				: 'assets/shared/mascot/mascot-droid.png',
	EASTERBUNNY 		: 'assets/shared/mascot/mascot-easterbunny.png',
	FIREFIGHTER 		: 'assets/shared/mascot/mascot-firefighter.png',
	FISHING 			: 'assets/shared/mascot/mascot-fishing.png',
	FLYING_ROCKET		: 'assets/shared/mascot/mascot-flying-rocket.png',
	GINI 				: 'assets/shared/mascot/mascot-gini.png',
	GRADUATION 			: 'assets/shared/mascot/mascot-graduation.png',
	HAVING_AN_IDEA 		: 'assets/shared/mascot/mascot-having-an-idea.png',
	IN_SPACE 			: 'assets/shared/mascot/mascot-in-space.png',
	KING 				: 'assets/shared/mascot/mascot-king.png',
	NIGHT				: 'assets/shared/mascot/mascot-night.png',
	NINJA 				: 'assets/shared/mascot/mascot-ninja.png',
	PAINTING 			: 'assets/shared/mascot/mascot-painting.png',
	PLAYING_GUITAR 		: 'assets/shared/mascot/mascot-playing-guitar.png',
	POLICE 				: 'assets/shared/mascot/mascot-police.png',
	READING 			: 'assets/shared/mascot/mascot-reading.png',
	SAMURAI 			: 'assets/shared/mascot/mascot-samurai.png',
	SCUBA_DIVING 		: 'assets/shared/mascot/mascot-scuba-diving.png',
	SECURITY 			: 'assets/shared/mascot/mascot-security.png',
	SKATING 			: 'assets/shared/mascot/mascot-skating.png',
	SKIING 				: 'assets/shared/mascot/mascot-skiing.png',
	SOCCER 				: 'assets/shared/mascot/mascot-soccer.png',
	SUPERHERO 			: 'assets/shared/mascot/mascot-superhero.png',
	TREADMILL 			: 'assets/shared/mascot/mascot-treadmill.png',
	VIKING 				: 'assets/shared/mascot/mascot-viking.png',
	WAVING 				: 'assets/shared/mascot/mascot-waving.png',
	WEIGHTLIFTING 		: 'assets/shared/mascot/mascot-weightlifting.png',
	WINNING_BOXING 		: 'assets/shared/mascot/mascot-winning-boxing.png',
	OLD_MAN 			: 'assets/shared/mascot/mascot-old-man.png',
	WITH_LIGHTSABER 	: 'assets/shared/mascot/mascot-with-lightsaber.png',
	WITH_PRESENT_A 		: 'assets/shared/mascot/mascot-with-present-a.png',
	WITH_PRESENT_B 		: 'assets/shared/mascot/mascot-with-present-b.png',
	ZOMBIE 				: 'assets/shared/mascot/mascot-zombie.png',
	BF_ANNOUNCEMENT 	: 'assets/shared/mascot/mascot-black-friday-announcement.png',
	BF_SHOPPING_BAG 	: 'assets/shared/mascot/mascot-black-friday-shopping-bag.png',
	BF_SHOPPING_CHART 	: 'assets/shared/mascot/mascot-black-friday-shopping-cart.png',
	BF_SHOPPING_CART 	: 'assets/shared/mascot/mascot-with-present-b.png'
};
