import { IDisplateText, IGlobalText, IMbaText } from '@apps/flying/shared';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';
import { EnumAiModel } from '@domains/ai-toolkit/shared';

import { FlyingAiTemplateSchema, IFlyingAiTemplate } from '../../interfaces';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_GENERATE_LISTING_LIMITS: IFlyingGenerateListingConfig = {
	FLY_ID_LENGTH		: 120,
	MAX_KEYWORDS		: 100,
	KEYWORD_LENGTH		: 120,
	TOPIC_LENGTH		: 250,
	INSTRUCTIONS_LENGTH	: 250,
	MAX_LIST_SIZE		: 500,
	GLOBAL_DEFAULTS		: {				// these are the defaults used to generate text length for global
		MAX_TITLE		: 120,
		MAX_DESCRIPTION : 500,
		MAX_KEYWORDS 	: 50
	},
	MBA_DEFAULTS		: {				// these are the defaults used to generate text length for mba
		MAX_BRAND		: 50,
		MAX_TITLE		: 60,
		MAX_BULLET1 	: 256,
		MAX_BULLET2 	: 256,
		MAX_DESCRIPTION : 500
	},
	AI_MODELS			: {
		VISION			: [EnumAiModel.Gpt4o],												// the order matter, it is the order in by which the default is selected for a user
		LISTING			: [EnumAiModel.Gpt4o, EnumAiModel.Gpt4Turbo, EnumAiModel.Gpt4, EnumAiModel.Gpt3_5Turbo]	// the order matter, it is the order in by which the default is selected for a user
	}
};

interface IFlyingGenerateListingConfig {
	FLY_ID_LENGTH		: number;
	MAX_KEYWORDS		: number;
	KEYWORD_LENGTH		: number;
	TOPIC_LENGTH		: number;
	INSTRUCTIONS_LENGTH	: number;
	MAX_LIST_SIZE		: number;
	GLOBAL_DEFAULTS		: {				// these are the defaults used to generate text length for global
		MAX_TITLE		: number;
		MAX_DESCRIPTION : number;
		MAX_KEYWORDS 	: number;
	};
	MBA_DEFAULTS		: {				// these are the defaults used to generate text length for mba
		MAX_BRAND		: number;
		MAX_TITLE		: number;
		MAX_BULLET1 	: number;
		MAX_BULLET2 	: number;
		MAX_DESCRIPTION : number;
	};
	AI_MODELS			: {
		VISION			: EnumAiModel[];
		LISTING			: EnumAiModel[];
	};
}


/**------------------------------------------------------
 * Requests Vision
 */
export interface IFlyingGenerateByVisionRequest {
	model			: EnumAiModel;
	entries			: IFlyingGenerateByVisionParams[];
}

export interface IFlyingGenerateByVisionParams {
	flyId			: string;
	base64ImageUrl	: string;
	topic			: string;
	instructions	: string;			// custom instructions
}


/**------------------------------------------------------
 * Request Keywords
 */
export interface IFlyingGenerateByKeywordsRequest {
	model			: EnumAiModel;
	entries			: IFlyingGenerateByKeywordsParams[];
}

export interface IFlyingGenerateByKeywordsParams {
	flyId			: string;
	mainKeywords	: string[];
	fillKeywords	: string[];
}


/**------------------------------------------------------
 * Request Template
 */
export interface IFlyingGenerateByTemplateRequest {
	model			: EnumAiModel;
	entries			: IFlyingGenerateByTemplateParams[];
}

export interface IFlyingGenerateByTemplateParams {
	flyId			: string;
	template		: IFlyingAiTemplate;
	instructions	: string;			// custom instructions
}


/**------------------------------------------------------
 * Response
 */
export interface IFlyingGeneratedListingResponse {
	flyId			: string;
	global 	 		: IGlobalText[]	| null;
	mba  	 		: IMbaText[]	| null;
	displate 		: IDisplateText	| null;
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingGenerateByVisionRequestSchema implements ValidatorSchema<IFlyingGenerateByVisionRequest> {
	model	 	= Validator.Enum(EnumAiModel).isWhitelisted(FLYING_GENERATE_LISTING_LIMITS.AI_MODELS.VISION).isNotEmpty();
	entries		= Validator.Array().isNotEmpty().isMaxSize(FLYING_GENERATE_LISTING_LIMITS.MAX_LIST_SIZE).inspectEach<IFlyingGenerateByVisionParams>({
		flyId			: Validator.String().isNotEmpty().isMaxLength(FLYING_GENERATE_LISTING_LIMITS.FLY_ID_LENGTH),
		base64ImageUrl	: Validator.String().isNotEmpty(),
		topic			: Validator.String().isMaxLength(FLYING_GENERATE_LISTING_LIMITS.TOPIC_LENGTH).hasNoHtmlScripts(),
		instructions	: Validator.String().isMaxLength(FLYING_GENERATE_LISTING_LIMITS.INSTRUCTIONS_LENGTH).hasNoHtmlScripts()
	});
}

export class FlyingGenerateByKeywordsRequestSchema implements ValidatorSchema<IFlyingGenerateByKeywordsRequest> {
	model 		= Validator.Enum(EnumAiModel).isWhitelisted(FLYING_GENERATE_LISTING_LIMITS.AI_MODELS.LISTING).isNotEmpty();
	entries		= Validator.Array().isNotEmpty().isMaxSize(FLYING_GENERATE_LISTING_LIMITS.MAX_LIST_SIZE).inspectEach<IFlyingGenerateByKeywordsParams>({
		flyId		: Validator.String().isNotEmpty().isMaxLength(FLYING_GENERATE_LISTING_LIMITS.FLY_ID_LENGTH),
		mainKeywords: Validator.Array().isNotEmpty().isMaxSize(FLYING_GENERATE_LISTING_LIMITS.MAX_KEYWORDS)
						.each(Validator.String().isNotEmpty().isMaxLength(FLYING_GENERATE_LISTING_LIMITS.KEYWORD_LENGTH)
						.hasNoHtmlScripts().toLowerCase().trim()).isNotEmpty(),
		fillKeywords: Validator.Array().isMaxSize(FLYING_GENERATE_LISTING_LIMITS.MAX_KEYWORDS)
						.each(Validator.String().isNotEmpty().isMaxLength(FLYING_GENERATE_LISTING_LIMITS.KEYWORD_LENGTH)
						.hasNoHtmlScripts().toLowerCase().trim())
	});
}

export class FlyingGenerateByTemplateRequestSchema implements ValidatorSchema<IFlyingGenerateByTemplateRequest> {
	model	 	= Validator.Enum(EnumAiModel).isWhitelisted(FLYING_GENERATE_LISTING_LIMITS.AI_MODELS.LISTING).isNotEmpty();
	entries		= Validator.Array().isNotEmpty().isMaxSize(FLYING_GENERATE_LISTING_LIMITS.MAX_LIST_SIZE).inspectEach<IFlyingGenerateByTemplateParams>({
		flyId		: Validator.String().isNotEmpty().isMaxLength(FLYING_GENERATE_LISTING_LIMITS.FLY_ID_LENGTH),
		template	: Validator.Object().inspect<IFlyingAiTemplate>(FlyingAiTemplateSchema),
		instructions: Validator.String().isMaxLength(FLYING_GENERATE_LISTING_LIMITS.INSTRUCTIONS_LENGTH).hasNoHtmlScripts()
	});
}

export class FlyingGeneratedListingResponseSchema implements ValidatorSchema<IFlyingGeneratedListingResponse> {
	flyId		= Validator.String().isNotEmpty();
	global 	 	= Validator.Array().optional();
	mba  	 	= Validator.Array().optional();
	displate 	= Validator.Object().optional();
}
