import { EnumLanguage, EnumLanguageLocale } from '@libs/constants';

import { UtilEnum } from './util-enum';


/**------------------------------------------------------
 * Internationalization (i18n)
 * ---------------------------
 * > This utility is used to select the appropriate language
 * > that should be loaded and shown based. This utility is
 * > part of how we handle the internationalization (i18n) of
 * > our landing pages and applications. Read more about it
 * > in the wiki.
 */
export class UtilLocale {

	//** Helper Variables */
	static locale	  : EnumLanguage | EnumLanguageLocale = EnumLanguage.EN;
	static isLocaleSet: boolean = false;

	constructor(
		private utilEnum: UtilEnum
	) {}


	setLocale(languageLocale: EnumLanguage | EnumLanguageLocale): void {

		//0 - is the language valid
		if (UtilLocale.isLocaleSet) throw new Error(`UtilLocale => setLanguage => FATAL ERROR: the locale was already set`);
		if (!this.utilEnum.isValid(EnumLanguage, languageLocale) && !this.utilEnum.isValid(EnumLanguageLocale, languageLocale)) throw new Error(`UtilLocale => setLanguage => FATAL ERROR: languageLocale of "${languageLocale}" is not valid`);

		//1 - set the language
		UtilLocale.locale 	   = languageLocale;
		UtilLocale.isLocaleSet = true;
	}

	getTranslation<T extends object>(localeContents: Partial<Record<EnumLanguage | EnumLanguageLocale, T>>): T {

		//0 - try to get the right content
		const content: T = localeContents[UtilLocale.locale]!;
		if (!content) throw new Error(`UtilLocale => getTranslation => FATAL ERROR: no content defined for languageLocale of "${UtilLocale.locale}"`);

		//1 - return the content
		return content;
	}
}
