import { UtilString } from './util-string';


/**------------------------------------------------------
 * Date Utilities
 * --------------
 * > Info: Containing all functionalities related to the js
 * > dates, like formatting of the date string, adding and
 * > subtracting from dates, ...
 */
export class UtilDate {

	//** Configurations */
	private readonly DAY_NAMES 		  	: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	private readonly DAY_NAMES_SHORT   	: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	private readonly MONTH_NAMES 	  	: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	private readonly MONTH_NAMES_SHORT  : string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	private readonly DATE_FORMAT_OPTIONS: IUtilFormatOptions = {
		addLeading 	: true
	};

	constructor(
		private utilString: UtilString
	) {}


	/**------------------------------------------------------
	 * Check Valid Data Object
	 */
	isValidDate(date: Date): boolean {
		return date instanceof Date && date.toString() !== 'Invalid Date' && !isNaN(date.getTime());
	}
	isInvalidDate(date: Date): boolean {
		return !this.isValidDate(date);
	}


	/**------------------------------------------------------
	 * Check Valid Data String
	 */
	isValidDateString(dateString: string): boolean {

		//0 - check the function call
		if (!this.utilString.isString(dateString) || this.utilString.isEmpty(dateString)) return false;

		//1 - check if string is a valid date
		return this.isValidDate(new Date(dateString));
	}
	isInvalidDateString(dateString: string): boolean {
		return !this.isValidDateString(dateString);
	}


	/**------------------------------------------------------
	 * Day
	 */
	isToday(date: Date): boolean {
		this.checkDate(date);
		return date.toLocaleDateString() === new Date().toLocaleDateString();
	}
	isYesterday(date: Date): boolean {
		this.checkDate(date);
		const yesterday: Date = this.subtractDays(new Date(), 1);
		return date.toDateString() === yesterday.toDateString();
	}
	isTomorrow(date: Date): boolean {
		this.checkDate(date);
		const tomorrow: Date = this.addDays(new Date(), 1);
		return date.toDateString() === tomorrow.toDateString();
	}

	isRangeValid(from: Date, to: Date): boolean {
		this.checkDates([from, to]);
		return from.getTime() <= to.getTime();
	}
	inPast(date: Date): boolean {
		this.checkDate(date);
		return date.getTime() < Date.now();
	}
	inFuture(date: Date): boolean {
		this.checkDate(date);
		return Date.now() < date.getTime();
	}
	isLeapYear(date: Date): boolean {
		return new Date(date.getFullYear(), 1, 29).getMonth() === 1;
	}

	hasDays(timeMs: number): boolean {
		return timeMs > (1000 * 60 * 60 * 24);
	}
	hasHours(timeMs: number): boolean {
		return timeMs > (1000 * 60 * 60);
	}
	hasMinutes(timeMs: number): boolean {
		return timeMs > (1000 * 60);
	}
	hasSeconds(timeMs: number): boolean {
		return timeMs > 1000;
	}


	/**------------------------------------------------------
	 * Check for Day in a Week
	 */
	isSunday(date: Date)	: boolean { return date.getDay() === 0; }
	isMonday(date: Date)	: boolean { return date.getDay() === 1; }
	isTuesday(date: Date)	: boolean { return date.getDay() === 2; }
	isWednesday(date: Date)	: boolean { return date.getDay() === 3; }
	isThursday(date: Date)	: boolean { return date.getDay() === 4; }
	isFriday(date: Date)	: boolean { return date.getDay() === 5; }
	isSaturday(date: Date)	: boolean { return date.getDay() === 6; }


	/**------------------------------------------------------
	 * Check for Month in a Year
	 */
	isJanuary(date: Date)	: boolean { return date.getMonth() === 0; }
	isFebruary(date: Date)	: boolean { return date.getMonth() === 1; }
	isMarch(date: Date)		: boolean { return date.getMonth() === 2; }
	isApril(date: Date)		: boolean { return date.getMonth() === 3; }
	isMay(date: Date)		: boolean { return date.getMonth() === 4; }
	isJune(date: Date)		: boolean { return date.getMonth() === 5; }
	isJuly(date: Date)		: boolean { return date.getMonth() === 6; }
	isAugust(date: Date)	: boolean { return date.getMonth() === 7; }
	isSeptember(date: Date)	: boolean { return date.getMonth() === 8; }
	isOctober(date: Date)	: boolean { return date.getMonth() === 9; }
	isNovember(date: Date)	: boolean { return date.getMonth() === 10; }
	isDecember(date: Date)	: boolean { return date.getMonth() === 11; }


	/**------------------------------------------------------
	 * Create New Date based on Today
	 */
	futureDateByHours(hours: number, date: Date = new Date()): Date {
		this.checkDate(date);
		if (hours < 0) throw new Error(`UtilDate => pastDateByMonth => FATAL ERROR: provided hours is negative (value: ${hours})`);
		return new Date(date.setHours(date.getHours() + hours));
	}
	pastDateByHours(hours: number, date: Date = new Date()): Date {
		this.checkDate(date);
		if (hours < 0) throw new Error(`UtilDate => pastDateByMonth => FATAL ERROR: provided hours is negative (value: ${hours})`);
		return new Date(date.setHours(date.getHours() - hours));
	}

	futureDateByDays(days: number, date: Date = new Date()): Date {
		this.checkDate(date);
		if (days < 0) throw new Error(`UtilDate => futureDateByDays => FATAL ERROR: provided days is negative (value: ${days})`);
		return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
	}
	pastDateByDays(days: number, date: Date = new Date()): Date {
		this.checkDate(date);
		if (days < 0) throw new Error(`UtilDate => pastDateByDays => FATAL ERROR: provided days is negative (value: ${days})`);
		return new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
	}

	futureDateByMonth(month: number, date: Date = new Date()): Date {
		this.checkDate(date);
		if (month < 0) throw new Error(`UtilDate => futureDateByMonth => FATAL ERROR: provided month is negative (value: ${month})`);
		return new Date(date.setMonth(date.getMonth() + month));
	}
	pastDateByMonth(month: number, date: Date = new Date()): Date {
		this.checkDate(date);
		if (month < 0) throw new Error(`UtilDate => pastDateByMonth => FATAL ERROR: provided month is negative (value: ${month})`);
		return new Date(date.setMonth(date.getMonth() - month));
	}


	/**------------------------------------------------------
	 * Set starting date based on date
	 */
	startDateOfMonth(date: Date): Date {
		this.checkDate(date);
		return new Date(date.getFullYear(), date.getMonth(), 1);
	}
	startDateOfYear(date: Date): Date {
		this.checkDate(date);
		return new Date(date.getFullYear(), 1, 1);
	}


	/**------------------------------------------------------
	 * Add to Date
	 */
	addSeconds(date: Date, seconds: number): Date {
		this.checkDate(date);
		if (seconds < 0) throw new Error(`UtilDate => addSeconds => FATAL ERROR: provided seconds are negative (value: ${seconds})`);
		date.setSeconds(date.getSeconds() + seconds);
		return date;
	}
	addMinutes(date: Date, minutes: number): Date {
		this.checkDate(date);
		if (minutes < 0) throw new Error(`UtilDate => addMinutes => FATAL ERROR: provided minutes are negative (value: ${minutes})`);
		date.setMinutes(date.getMinutes() + minutes);
		return date;
	}
	addHours(date: Date, hours: number): Date {
		this.checkDate(date);
		if (hours < 0) throw new Error(`UtilDate => addHours => FATAL ERROR: provided hours are negative (value: ${hours})`);
		date.setHours(date.getHours() + hours);
		return date;
	}
	addDays(date: Date, days: number): Date {
		this.checkDate(date);
		if (days < 0) throw new Error(`UtilDate => addDays => FATAL ERROR: provided days are negative (value: ${days})`);
		date.setDate(date.getDate() + days);
		return date;
	}
	addMonth(date: Date, month: number): Date {
		this.checkDate(date);
		if (month < 0) throw new Error(`UtilDate => addMonth => FATAL ERROR: provided month are negative (value: ${month})`);
		date.setMonth(date.getMonth() + month);
		return date;
	}
	addYears(date: Date, years: number): Date {
		this.checkDate(date);
		if (years < 0) throw new Error(`UtilDate => addYears => FATAL ERROR: provided years are negative (value: ${years})`);
		date.setFullYear(date.getFullYear() + years);
		return date;
	}


	/**------------------------------------------------------
	 * Subtract from Date
	 */
	subtractSeconds(date: Date, seconds: number): Date {
		this.checkDate(date);
		if (seconds < 0) throw new Error(`UtilDate => subtractSeconds => FATAL ERROR: provided seconds are negative (value: ${seconds})`);
		date.setSeconds(date.getSeconds() - seconds);
		return date;
	}
	subtractMinutes(date: Date, minutes: number): Date {
		this.checkDate(date);
		if (minutes < 0) throw new Error(`UtilDate => subtractMinutes => FATAL ERROR: provided minutes are negative (value: ${minutes})`);
		date.setMinutes(date.getMinutes() - minutes);
		return date;
	}
	subtractHours(date: Date, hours: number): Date {
		this.checkDate(date);
		if (hours < 0) throw new Error(`UtilDate => subtractHours => FATAL ERROR: provided hours are negative (value: ${hours})`);
		date.setHours(date.getHours() - hours);
		return date;
	}
	subtractDays(date: Date, days: number): Date {
		this.checkDate(date);
		if (days < 0) throw new Error(`UtilDate => subtractDays => FATAL ERROR: provided days are negative (value: ${days})`);
		date.setDate(date.getDate() - days);
		return date;
	}
	subtractMonth(date: Date, month: number): Date {
		this.checkDate(date);
		if (month < 0) throw new Error(`UtilDate => subtractMonth => FATAL ERROR: provided month are negative (value: ${month})`);
		date.setMonth(date.getMonth() - month);
		return date;
	}
	subtractYears(date: Date, years: number): Date {
		this.checkDate(date);
		if (years < 0) throw new Error(`UtilDate => subtractYears => FATAL ERROR: provided years are negative (value: ${years})`);
		date.setFullYear(date.getFullYear() - years);
		return date;
	}


	/**------------------------------------------------------
	 * Rounding Time
	 */
	roundToNearestDay(date: Date = new Date()): Date {
		const day: number = 1000 * 60 * 60 * 24;
		return new Date(Math.round(date.getTime() / day) * day);
	}
	roundUpToNearestDay(date: Date = new Date()): Date {
		const day: number = 1000 * 60 * 60 * 24;
		return new Date(Math.ceil(date.getTime() / day) * day);
	}
	roundDownToNearestDay(date: Date = new Date()): Date {
		const day: number = 1000 * 60 * 60 * 24;
		return new Date(Math.floor(date.getTime() / day) * day);
	}

	roundToNearestHour(date: Date = new Date()): Date {
		const hour: number = 1000 * 60 * 60;
		return new Date(Math.round(date.getTime() / hour) * hour);
	}
	roundUpToNearestHour(date: Date = new Date()): Date {
		const hour: number = 1000 * 60 * 60;
		return new Date(Math.ceil(date.getTime() / hour) * hour);
	}
	roundDownToNearestHour(date: Date = new Date()): Date {
		const hour: number = 1000 * 60 * 60;
		return new Date(Math.floor(date.getTime() / hour) * hour);
	}

	roundToNearestMinute(date: Date = new Date()): Date {
		const millisecond: number = 1000 * 60;
		return new Date(Math.round(date.getTime() / millisecond) * millisecond);
	}
	roundUpToNearestMinute(date: Date = new Date()): Date {
		const millisecond: number = 1000 * 60;
		return new Date(Math.ceil(date.getTime() / millisecond) * millisecond);
	}
	roundDownToNearestMinute(date: Date = new Date()): Date {
		const millisecond: number = 1000 * 60;
		return new Date(Math.floor(date.getTime() / millisecond) * millisecond);
	}

	roundToNearestSecond(date: Date = new Date()): Date {
		const second: number = 1000;
		return new Date(Math.round(date.getTime() / second) * second);
	}
	roundUpToNearestSecond(date: Date = new Date()): Date {
		const second: number = 1000;
		return new Date(Math.ceil(date.getTime() / second) * second);
	}
	roundDownToNearestSecond(date: Date = new Date()): Date {
		const second: number = 1000;
		return new Date(Math.floor(date.getTime() / second) * second);
	}


	/**------------------------------------------------------
	 * Compare Dates
	 */
	compare(date1: Date, date2: Date): boolean {
		this.checkDates([date1, date2]);
		return date1.getTime() === date2.getTime();
	}
	compareByDay(date1: Date, date2: Date): boolean {
		this.checkDates([date1, date2]);
		return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
	}
	compareByMonth(date1: Date, date2: Date): boolean {
		this.checkDates([date1, date2]);
		return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
	}
	compareByYear(date1: Date, date2: Date): boolean {
		this.checkDates([date1, date2]);
		return date1.getFullYear() === date2.getFullYear();
	}
	compareByDateString(date1: Date, date2: Date): boolean {
		this.checkDates([date1, date2]);
		return date1.toLocaleDateString() === date2.toLocaleDateString();
	}


	/**------------------------------------------------------
	 * Date differences
	 */
	yearDifference(date1: Date, date2: Date): number {
		const yearDiff: number = date1.getFullYear() - date2.getFullYear();
		return Math.abs(yearDiff);
	}
	monthDifference(date1: Date, date2: Date): number {
		const yearDiff		: number = this.yearDifference(date1, date2);
		const monthDiff		: number = Math.abs(date1.getMonth() - date2.getMonth());
		const totalMonthDiff: number = yearDiff * 12 + monthDiff;
		return totalMonthDiff;
	}
	dayDifference(date1: Date, date2: Date): number {
		const timeDiff	: number = Math.abs(date1.getTime() - date2.getTime());
		const dayDiff	: number = timeDiff / (1000 * 60 * 60 * 24);
		return dayDiff;
	}
	hourDifference(date1: Date, date2: Date): number {
		return this.dayDifference(date1, date2) * 24;
	}
	minuteDifference(date1: Date, date2: Date): number {
		return this.hourDifference(date1, date2) * 60;
	}
	secondDifference(date1: Date, date2: Date): number {
		return Math.abs(date1.getTime() - date2.getTime()) * 1000;
	}


	/**------------------------------------------------------
	 * Date to Formatted String
	 */
	formatToYmd(date: Date = new Date()): string {
		this.checkDate(date);
		return this.formatToIsoString(date).split('T')[0];
	}
	formatToYmdHms(date: Date = new Date()): string {
		this.checkDate(date);
		return this.formatToIsoString(date).replace(/T/, ' ').replace(/\..+/, '');
	}
	formatToIsoString(date: Date = new Date()): string {
		this.checkDate(date);
		const timeOffset  : number = date.getTimezoneOffset() * 60000; 									// offset in milliseconds
		const localIsoTime: string = new Date(date.getTime() - timeOffset).toISOString().slice(0, -1);
		return localIsoTime;
	}


	/**------------------------------------------------------
	 * Advanced Date Formatting
	 * ----------------
	 * > YYYY		-> year 			(example: 2021)
	 * > yy | YY	-> 2 digit year 	(example: 21, from '10.05.2021')
	 * > MM			-> month 			(example: 05, from '10.05.2021')
	 * > DD | D		-> day 				(example: 10, from '10.05.2021')
	 * > WW | ll	-> week of the year (example: 23, from '10.05.2021')
	 * > 			-> week of the year (example: 23, from '10.05.2021')
	 * ....................
	 * > HH			-> hours 			(example: 14, from '10.05.2021 T 14:25:01')
	 * > mm			-> minutes 			(example: 25, from '10.05.2021 T 14:25:01')
	 * > ss			-> seconds 			(example: 01, from '10.05.2021 T 14:25:01')
	 * > ms | SSS	-> milliseconds 	(example: 011, from '10.05.2021 T 14:25:01:011')
	 * ....................
	 * > MONTH 		-> month as text 	(example: February, from '10.02.2021')
	 * > month		-> month as text 	(example: Feb, 		from '10.02.2021')
	 * > DAY		-> day as text 		(example: Monday, 	from '10.05.2021')
	 * > day		-> day as text 		(example: Mon, 		from '10.05.2021')
	 */
	format(format: string, date: Date = new Date(), options: IUtilFormatOptions = this.DATE_FORMAT_OPTIONS): string {
		this.checkDate(date);

		//0 - set the month & days
		format = format.replace('day',   	this.DAY_NAMES_SHORT[date.getDay()]);
		format = format.replace('DAY',   	this.DAY_NAMES[date.getDay()]);
		format = format.replace('month', 	this.MONTH_NAMES_SHORT[date.getMonth()]);
		format = format.replace('MONTH', 	this.MONTH_NAMES[date.getMonth()]);

		//1 - date formations
		format = format.replace('YYYY', 	`${date.getFullYear()}`);
		format = format.replace(/YY|yy/g, 	`${date.getFullYear()}`.substring(2));
		format = format.replace(/DD|D/g, 	options?.addLeading ? this.utilString.addLeadingZeros(date.getDate(), 2) : `${date.getDate()}`);
		format = format.replace('MMM',  	this.MONTH_NAMES_SHORT[date.getMonth()]);
		format = format.replace('MM',   	options?.addLeading ? this.utilString.addLeadingZeros(date.getMonth() + 1, 2) : `${date.getMonth() + 1}`);
		format = format.replace(/WW|ll/g, 	`${this.getWeek(date)}`);

		//2 - time formations
		format = format.replace('HH',  		options?.addLeading ? this.utilString.addLeadingZeros(date.getHours(), 2)   : `${date.getHours()}`);
		format = format.replace('h',   		options?.addLeading ? this.utilString.addLeadingZeros(date.getHours(), 2)   : `${date.getHours()}`);
		format = format.replace('mm',  		options?.addLeading ? this.utilString.addLeadingZeros(date.getMinutes(), 2) : `${date.getMinutes()}`);
		format = format.replace('ss',  		options?.addLeading ? this.utilString.addLeadingZeros(date.getSeconds(), 2) : `${date.getSeconds()}`);
		format = format.replace(/ms|SSS/g,  options?.addLeading ? this.utilString.addLeadingZeros(date.getSeconds(), 2) : `${date.getMilliseconds()}`);

		return format;
	}

	formatPlaceholders(format: string, date: Date = new Date(), options: IUtilFormatOptions = this.DATE_FORMAT_OPTIONS): string {
		this.checkDate(date);

		//0 - date formations
		format = format.replace(/\[(YYYY)\]/g, 	`${date.getFullYear()}`);
		format = format.replace(/\[(YY|yy)\]/g, `${date.getFullYear()}`.substring(2));
		format = format.replace(/\[(DD|D)\]/g, 	options?.addLeading ? this.utilString.addLeadingZeros(date.getDate(), 2) : `${date.getDate()}`);
		format = format.replace(/\[MMM\]/g, 	this.MONTH_NAMES_SHORT[date.getMonth()]);
		format = format.replace(/\[MM\]/g, 		options?.addLeading ? this.utilString.addLeadingZeros(date.getMonth() + 1, 2) : `${date.getMonth() + 1}`);
		format = format.replace(/\[(WW|ll)\]/g, `${this.getWeek(date)}`);

		//1 - set the month & days
		format = format.replace(/\[day\]/g, 	this.DAY_NAMES_SHORT[date.getDay()]);
		format = format.replace(/\[DAY\]/g, 	this.DAY_NAMES[date.getDay()]);
		format = format.replace(/\[month\]/g, 	this.MONTH_NAMES_SHORT[date.getMonth()]);
		format = format.replace(/\[MONTH\]/g, 	this.MONTH_NAMES[date.getMonth()]);

		//2 - time formations
		format = format.replace(/\[HH\]/g, 		options?.addLeading ? this.utilString.addLeadingZeros(date.getHours(), 2) : `${date.getHours()}`);
		format = format.replace(/\[h\]/g, 		options?.addLeading ? this.utilString.addLeadingZeros(date.getHours(), 2) : `${date.getHours()}`);
		format = format.replace(/\[mm\]/g, 		options?.addLeading ? this.utilString.addLeadingZeros(date.getMinutes(), 2) : `${date.getMinutes()}`);
		format = format.replace(/\[ss\]/g, 		options?.addLeading ? this.utilString.addLeadingZeros(date.getSeconds(), 2) : `${date.getSeconds()}`);
		format = format.replace(/\[(ms|SSS)\]/g, options?.addLeading ? this.utilString.addLeadingZeros(date.getMilliseconds(), 3) : `${date.getMilliseconds()}`);

		return format;
	}


	/**------------------------------------------------------
	 * Format Duration
	 * ---------------
	 * > Returns the human readable format of the given number of milliseconds.
	 * > source  : https://decipher.dev/30-seconds-of-typescript/docs/formatDuration/
	 * > example : 	34325055574   ->   "397 days, 6 hours, 44 minutes, 15 seconds, 574 milliseconds"
	 */
	formatDuration(timeMs: number): string {

		//0 - extract the duration times
		if (timeMs === 0) return '0 milliseconds';
		const time: IUtilDurationTime = this.timeDuration(timeMs);

		//1 - create the time string
		return Object.entries(time).filter((value: [string, any]) => value[1] !== 0)
			.map(([key, value]: [string, any]) => `${value} ${key}${value !== 1 ? 's' : ''}`).join(', ');
	}
	formatDurationShort(timeMs: number): string {

		//0 - check the parameters
		timeMs = Math.abs(timeMs);
		if (timeMs === 0) return '0 ms';
		if (timeMs < 0)   throw new Error(`UtilDate => formatDuration => FATAL ERROR: the provided time_ms is smaller than 0 and can therefore not be formatted (time_ms: "${timeMs}")`);

		//1 - extract the duration times
		const time: IUtilDurationTimeShort = {
			d	: Math.floor(timeMs / 86400000), // eslint-disable-line id-denylist
			h	: Math.floor(timeMs / 3600000) % 24,
			m	: Math.floor(timeMs / 60000) % 60,
			s	: Math.floor(timeMs / 1000) % 60,
			ms	: Math.floor(timeMs) % 1000
		};

		//2 - create the time string
		return Object.entries(time).filter((value: [string, any]) => value[1] !== 0)
			.map(([key, value]: [string, any]) => `${value} ${key}`).join(', ');
	}


	/**------------------------------------------------------
	 * Calculate Duration
	 */
	timeDuration(timeMs: number): IUtilDurationTime {

		//0 - check the parameters
		timeMs = Math.abs(timeMs);
		if (timeMs < 0) throw new Error(`UtilDate => formatDuration => FATAL ERROR: the provided time_ms is smaller than 0 and can therefore not be formatted (time_ms: "${timeMs}")`);

		//1 - extract the duration times
		const time: IUtilDurationTime = {
			day			: Math.floor(timeMs / 86400000),
			hour		: Math.floor(timeMs / 3600000) % 24,
			minute		: Math.floor(timeMs / 60000) % 60,
			second		: Math.floor(timeMs / 1000) % 60,
			millisecond	: Math.floor(timeMs) % 1000
		};

		//2 - return the duration time
		return time;
	}


	/**------------------------------------------------------
	 * Estimated Time
	 */
	calculateRemainingTime(startDate: Date, progressPercent: number, scale: EnumUtilRemainingTimeScale = EnumUtilRemainingTimeScale.Second): number {

		//0 - check the parameters
		if (progressPercent === 0) return 0;		// for initial value the remaining time can not be calculated
		if (progressPercent < 0 || progressPercent > 100) throw new Error(`UtilDate => calculateRemainingTime => FATAL ERROR: the progress not be lower then 0% and not be more then 100% (progress: ${progressPercent})`);

		//1 - calculate the remaining time in ms
		const timePerPercent	: number = (new Date().getTime() - startDate.getTime()) / progressPercent;
		const remainingPercent	: number = 100 - progressPercent;
		const timeEstimationDate: Date   = new Date(timePerPercent * remainingPercent);

		//2 - round the time based on scale
		switch (scale) {
			case EnumUtilRemainingTimeScale.Second:
				return this.roundUpToNearestSecond(timeEstimationDate).getTime();

			case EnumUtilRemainingTimeScale.Minute:
				return this.roundUpToNearestMinute(timeEstimationDate).getTime();

			case EnumUtilRemainingTimeScale.Hour:
				return this.roundUpToNearestHour(timeEstimationDate).getTime();

			case EnumUtilRemainingTimeScale.Day:
				return this.roundUpToNearestDay(timeEstimationDate).getTime();

			default:
				throw new Error(`UtilDate => calculateRemainingTime => FATAL ERROR: scale of "${scale}" was not defined`);
		}
	}


	/**------------------------------------------------------
	 * Specific Getters
	 */
	getWeek(date: Date): number {
		const target: Date   = new Date(date);
		const dayNr : number = (date.getDay() + 6) % 7;
		target.setDate(target.getDate() - dayNr + 3);
		const firstThursday: number = target.valueOf();
		target.setMonth(0, 1);
		if (target.getDay() !== 4) {
			target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
		}
		return 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000);
	}
	getUtcTime(): Date {
		return new Date(new Date().toUTCString());
	}
	getDaysInRange(dateFrom: Date, dateTo: Date): Date[] {

		//0 - loop over each day to add it to an array of dates
		const allDays: Date[] = [];
		// eslint-disable-next-line no-unmodified-loop-condition
		for (const currentDate: Date = new Date(dateFrom); currentDate <= dateTo; currentDate.setDate(currentDate.getDate() + 1)) {
			allDays.push(new Date(currentDate));
		}

		//1 - return the dates of all days between
		return allDays;
	}

	convertUtcToLocal(date: Date): Date {
		const year	 : number = date.getUTCFullYear();
		const month	 : number = date.getUTCMonth();
		const day	 : number = date.getUTCDate();
		const hours	 : number = date.getUTCHours();
		const minutes: number = date.getUTCMinutes();
		const seconds: number = date.getUTCSeconds();
		return new Date(year, month, day, hours, minutes, seconds);
	}


	/**------------------------------------------------------
	 * NgbDate Helpers
	 * => https://ng-bootstrap.github.io/#/components/datepicker/api#NgbDate
	 */
	isValidNgbDate(ngbDate: IUtilNgbDateStruct): boolean {
		if (ngbDate.day <= 0 || ngbDate.month <= 0 || ngbDate.year < 0) return false;
		const date: Date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
		return this.isValidDate(date);
	}
	toNgbDate(date: Date = new Date()): IUtilNgbDateStruct {
		this.checkDate(date);
		const ngbDate: IUtilNgbDateStruct = {
			day 	: date.getDate(),
			month 	: date.getMonth() + 1,
			year	: date.getFullYear()
		};
		return ngbDate;
	}
	fromNgbDate(ngbDate: IUtilNgbDateStruct): Date {
		if (!this.isValidNgbDate(ngbDate)) throw new Error(`UtilDate => fromNgbDate => FATAL ERROR: provided date is invalid (years: ${ngbDate.year} / month: ${ngbDate.month} / day: ${ngbDate.day})`);
		const date: Date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
		return date;
	}
	isNgbDateRangeValid(ngbFrom: IUtilNgbDateStruct, ngbTo: IUtilNgbDateStruct): boolean {
		return this.isRangeValid(this.fromNgbDate(ngbFrom), this.fromNgbDate(ngbTo));
	}


	/**------------------------------------------------------
	 * Helper Validation Checks
	 */
	private checkDate(date: Date) {
		this.checkDates([date]);
	}
	private checkDates(dateArray: Date[]) {
		for (const date of dateArray) {
			if (!this.isValidDate(date)) throw new Error(`UtilDate => checkDates => FATAL ERROR: provided date is invalid`);
		}
	}
}


//** Interfaces --------------------------------- */
interface IUtilNgbDateStruct {
	day 		: number;
	month 		: number;
	year		: number;
}

interface IUtilFormatOptions {
	addLeading ?: boolean;
}

export interface IUtilDurationTime {
	day			: number;
	hour		: number;
	minute		: number;
	second		: number;
	millisecond : number;
}

interface IUtilDurationTimeShort {
	d			: number; // eslint-disable-line id-denylist
	h			: number;
	m			: number;
	s			: number;
	ms 			: number;
}


//** Enums -------------------------------------- */
export enum EnumUtilRemainingTimeScale {
	Day		= 'day',
	Hour	= 'hour',
	Minute	= 'minute',
	Second	= 'second'
}
